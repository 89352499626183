import { map } from 'lodash';
import { PropsWithChildren, useRef, useState } from 'react';
import NODATA from 'src/assets/images/no-data.png';
import { Button } from 'src/components/common/Button';
import ScheduleAndTimePicker from 'src/components/common/ScheduleAndTimePicker';
import SelectBar, { SelectBarOptionProps } from 'src/components/common/SelectBar';
import SVGIcon from 'src/components/icon/SVGIcon';
import { useIBDeadlineCreateDeadline } from 'src/generated/endpoint';
import { DeadlineType, IBDeadlineGetItemsType, RequestIBDeadlineDto, ResponseIBDeadlineDto } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import { twMerge } from 'tailwind-merge';
import AlertV2 from '../../common/AlertV2';
import { ButtonV2 } from '../../common/ButtonV2';
import { Typography } from '../../common/Typography';
import ColorSVGIcon from '../../icon/ColorSVGIcon';
import { DEADLINE_TYPE_KOR } from './Coordinator_Schedule';

interface Coordinator_Schedule_AddScheduleProps {
  type: IBDeadlineGetItemsType;
  modalOpen: boolean;
  setModalClose: () => void;
  handleBack?: () => void;
  scheduleData?: ResponseIBDeadlineDto;
  onSuccess?: () => void;
}

interface SelectBarProps extends SelectBarOptionProps {
  items?: SelectBarOptionProps[];
}

const DeadLineTypeItems = [
  {
    id: 1,
    value: DeadlineType.EE_ESSAY,
    type: IBDeadlineGetItemsType.IB_EE,
    text: '에세이',
  },
  {
    id: 2,
    value: DeadlineType.EE_RRS,
    type: IBDeadlineGetItemsType.IB_EE,
    text: 'RRS',
  },
  {
    id: 3,
    value: DeadlineType.EE_PROPOSAL,
    type: IBDeadlineGetItemsType.IB_EE,
    text: '제안서',
  },
  {
    id: 4,
    value: DeadlineType.EE_RPPF_1,
    type: IBDeadlineGetItemsType.IB_EE,
    items: [
      {
        id: 1,
        value: DeadlineType.EE_RPPF_1,
        text: '1차',
      },
      {
        id: 2,
        value: DeadlineType.EE_RPPF_2,
        text: '2차',
      },
      {
        id: 3,
        value: DeadlineType.EE_RPPF_3,
        text: '3차',
      },
    ],
    text: 'RPPF',
  },
  {
    id: 5,
    value: DeadlineType.TOK_TKPPF_1,
    type: IBDeadlineGetItemsType.IB_TOK,
    items: [
      {
        id: 1,
        value: DeadlineType.TOK_TKPPF_1,
        text: '1차',
      },
      {
        id: 2,
        value: DeadlineType.TOK_TKPPF_2,
        text: '2차',
      },
      {
        id: 3,
        value: DeadlineType.TOK_TKPPF_3,
        text: '3차',
      },
    ],
    text: 'TKPPF',
  },
  {
    id: 6,
    value: DeadlineType.TOK_MOCK_EXHIBITION,
    type: IBDeadlineGetItemsType.IB_TOK,
    text: '모의 전시회',
  },
  {
    id: 7,
    value: DeadlineType.TOK_FINAL_EXHIBITION,
    type: IBDeadlineGetItemsType.IB_TOK,
    text: '최종 전시회',
  },
  {
    id: 8,
    value: DeadlineType.TOK_EXHIBITION_PLAN,
    type: IBDeadlineGetItemsType.IB_TOK,
    text: '전시회 기획안',
  },
  {
    id: 9,
    value: DeadlineType.TOK_ESSAY_OUTLINE,
    type: IBDeadlineGetItemsType.IB_TOK,
    text: '에세이 아웃라인',
  },
  {
    id: 10,
    value: DeadlineType.TOK_ESSAY,
    type: IBDeadlineGetItemsType.IB_TOK,
    text: '에세이',
  },
  {
    id: 11,
    value: DeadlineType.TOK_RRS,
    type: IBDeadlineGetItemsType.IB_TOK,
    text: 'RRS',
  },
  {
    id: 12,
    value: DeadlineType.CAS_INTERVIEW_1,
    type: IBDeadlineGetItemsType.IB_CAS,
    items: [
      {
        id: 1,
        value: DeadlineType.CAS_INTERVIEW_1,
        text: '1차',
      },
      {
        id: 2,
        value: DeadlineType.CAS_INTERVIEW_2,
        text: '2차',
      },
      {
        id: 3,
        value: DeadlineType.CAS_INTERVIEW_3,
        text: '3차',
      },
    ],
    text: '인터뷰일지',
  },
  {
    id: 13,
    value: DeadlineType.CAS_REFLECTION_DIARY,
    type: IBDeadlineGetItemsType.IB_CAS,
    text: '성찰일지',
  },
  {
    id: 14,
    value: DeadlineType.CAS_ACTIVITY_LOG,
    type: IBDeadlineGetItemsType.IB_CAS,
    text: '활동일지',
  },
  {
    id: 15,
    value: DeadlineType.CAS_PLAN,
    type: IBDeadlineGetItemsType.IB_CAS,
    text: '계획서',
  },
];

export function Coordinator_Schedule_AddSchedule({
  type,
  modalOpen,
  setModalClose,
  handleBack,
  onSuccess,
}: PropsWithChildren<Coordinator_Schedule_AddScheduleProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [deadlineType, setDeadlineType] = useState<DeadlineType>();
  const [item, setItem] = useState<DeadlineType>();
  const [createDeadlines, setCreateDeadlines] = useState<RequestIBDeadlineDto[]>([]);

  const reset = () => {
    setCreateDeadlines([]);
    setItem(undefined);
    setDeadlineType(undefined);
  };

  const { mutate: createDeadline } = useIBDeadlineCreateDeadline({ mutation: { onSuccess } });

  const handleUpdateDeadline = (dto: Partial<RequestIBDeadlineDto>, index: number) => {
    setCreateDeadlines((prev) => {
      const value = structuredClone(prev);
      value[index] = { ...prev[index], ...dto };
      return value;
    });
  };

  const handleDeleteDeadline = (index: number) => {
    setCreateDeadlines((prev) => {
      const value = structuredClone(prev);
      value.splice(index, 1);
      return value;
    });
  };

  const handleSubmit = () => {
    for (const deadline of createDeadlines) {
      createDeadline({ data: deadline });
    }
    reset();
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const selectBarOptions = DeadLineTypeItems.filter((el) => el.type === type);

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-black bg-opacity-50 ${
        !modalOpen && 'hidden'
      }`}
    >
      <div className={`relative w-[632px] overflow-hidden rounded-xl bg-white px-8`}>
        <div className="sticky top-0 z-10 flex h-[88px] items-center justify-between bg-white/70 pb-6 pt-8 backdrop-blur-[20px]">
          <Typography variant="title1">마감기한 설정</Typography>
          <ColorSVGIcon.Close
            color="gray700"
            size={32}
            onClick={() => {
              setModalClose();
              reset();
            }}
          />
        </div>

        <div className="flex items-center justify-between py-2">
          <div className="flex w-max flex-row items-center gap-2">
            <SelectBar
              options={selectBarOptions}
              value={deadlineType}
              onChange={(value: any) => {
                setDeadlineType(value);
                setItem(undefined);
              }}
              placeholder="항목"
              size={40}
              containerWidth="w-30"
            />
            {selectBarOptions.find((el) => el.value === deadlineType)?.items && (
              <SelectBar
                options={selectBarOptions.find((el) => el.value === deadlineType)?.items || []}
                value={item}
                onChange={(value: any) => setItem(value)}
                placeholder="차시"
                size={40}
                containerWidth="w-30"
              />
            )}
          </div>
          <ButtonV2
            variant="outline"
            size={40}
            color="gray400"
            disabled={!deadlineType}
            className="flex items-center justify-center gap-1 whitespace-pre"
            onClick={() => {
              const _deadlineType = item || deadlineType;
              if (!_deadlineType) return;
              if (map(createDeadlines, 'type').includes(_deadlineType)) return;
              setCreateDeadlines(
                createDeadlines.concat({
                  type: _deadlineType,
                  deadlineTime: '',
                  remindDays: [],
                }),
              );
            }}
          >
            <SVGIcon.Plus color="gray700" size={16} weight="bold" />
            추가
          </ButtonV2>
        </div>

        <div ref={scrollRef} className="scroll-box flex max-h-[608px] flex-col gap-4 overflow-auto pb-8 pt-4">
          {!createDeadlines.length && (
            <div className="flex flex-col items-center space-y-4 p-4">
              <img src={NODATA} className="h-12 w-[43px] object-cover" />
              <div>설정할 항목을 추가해주세요.</div>
            </div>
          )}
          {createDeadlines.map((data, index) => {
            return (
              <div className="rounded-lg bg-gray-50" key={index}>
                <div className="flex items-center justify-between border-b border-gray-200 p-4">
                  <Typography variant="title2">{DEADLINE_TYPE_KOR[data.type]}</Typography>
                  <ColorSVGIcon.Close
                    className="cursor-pointer"
                    color="gray700"
                    size={32}
                    onClick={() => handleDeleteDeadline(index)}
                  />
                </div>
                <div className="flex items-center justify-between p-4">
                  <div className="flex w-[235px] flex-col gap-3">
                    <Typography variant="title3" className="font-semibold">
                      마감기한
                    </Typography>
                    <div className="relative">
                      <div
                        className={`flex h-10 w-full items-center gap-2 rounded-lg border border-primary-gray-200 bg-white px-3 py-[9px] focus:outline-none focus:ring-0 ${
                          isFocused && 'border-primary-gray-700'
                        }`}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onClick={() => setCalendarOpen(!calendarOpen)}
                      >
                        <SVGIcon.Calendar size={20} color="gray700" />
                        <input
                          className="w-full flex-1 border-none bg-white p-0 text-15 font-medium text-primary-gray-900 placeholder-primary-gray-400 caret-primary-blue-800 focus:border-primary-gray-700 focus:text-primary-gray-700 focus:outline-none focus:ring-0"
                          placeholder="마감기한 선택"
                          value={
                            data.deadlineTime
                              ? DateUtil.formatDate(new Date(data.deadlineTime), DateFormat['YYYY.MM.DD a hh:mm']) +
                                '까지'
                              : ''
                          }
                        />
                      </div>
                      {calendarOpen && (
                        <div className="fixed left-1/2 top-1/2 z-50 mt-2 -translate-x-1/2 -translate-y-1/2 transform">
                          <ScheduleAndTimePicker
                            initialDeadline={data.deadlineTime ? new Date(data.deadlineTime) : undefined}
                            onSave={(finalDate) => {
                              const finalDateString = finalDate?.toISOString() || '';
                              handleUpdateDeadline({ deadlineTime: finalDateString }, index);
                              setCalendarOpen(false);
                            }}
                            onCancel={() => setCalendarOpen(false)}
                          />
                        </div>
                      )}
                    </div>
                    {/* <TextInput
                      type="datetime-local"
                      defaultValue={data.deadlineTime}
                      value={data.deadlineTime}
                      onChange={(e) => handleUpdateDeadline({ deadlineTime: e.target.value }, index)}
                    /> */}
                  </div>
                  <div className="flex flex-col gap-3">
                    <Typography variant="title3" className="font-semibold">
                      알림주기
                    </Typography>
                    <div className="flex items-center space-x-2">
                      {[7, 3, 1, 0].map((day) => (
                        <Button.lg
                          key={day}
                          className={twMerge(
                            'h-[40px] border border-primary-gray-300 bg-white text-primary-gray-700 disabled:border-primary-gray-200 disabled:bg-primary-gray-100 disabled:text-primary-gray-400',
                            data.remindDays?.includes(day) &&
                              'border-primary-orange-400 bg-primary-orange-100 text-primary-orange-800',
                          )}
                          disabled={!data.deadlineTime}
                          onClick={() =>
                            handleUpdateDeadline(
                              {
                                remindDays: data.remindDays?.includes(day)
                                  ? (data.remindDays || []).filter((el) => el !== day)
                                  : (data.remindDays || []).concat(day),
                              },
                              index,
                            )
                          }
                        >
                          {day === 0 ? '당일' : `${day}일전`}
                        </Button.lg>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div
          className={
            'sticky bottom-0 flex h-[104px] justify-end gap-4 border-t border-t-primary-gray-100 bg-white/70 pb-8 pt-6 backdrop-blur-[20px]'
          }
        >
          <div className="flex justify-end gap-3">
            <ButtonV2
              variant="solid"
              color="gray100"
              size={48}
              onClick={() => {
                handleBack && handleBack();
                reset();
              }}
            >
              이전
            </ButtonV2>
            <ButtonV2 type="submit" variant="solid" color="orange800" size={48} onClick={handleSubmit}>
              저장하기
            </ButtonV2>
          </div>
        </div>
      </div>
      {isOpen && (
        <AlertV2 confirmText="확인" message={`일정이 \n저장되었습니다`} onConfirm={() => setIsOpen(!isOpen)} />
      )}
    </div>
  );
}
