import { useThemeQuestionGetThemeQuestionItemsByType } from 'src/generated/endpoint';
import { ThemeQuestionGetThemeQuestionItemsByTypeType } from 'src/generated/model';
export const useThemeQuestionFindAll = (type: ThemeQuestionGetThemeQuestionItemsByTypeType) => {
  const { data, isLoading, refetch } = useThemeQuestionGetThemeQuestionItemsByType({ type });

  return {
    data,
    isLoading,
    refetch,
  };
};
