/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type IBDeadlineGetItemsType = (typeof IBDeadlineGetItemsType)[keyof typeof IBDeadlineGetItemsType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IBDeadlineGetItemsType = {
  IB_EE: 'IB_EE',
  IB_CAS: 'IB_CAS',
  IB_TOK: 'IB_TOK',
  IB_ALL: 'IB_ALL',
} as const;
