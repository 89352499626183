/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type EssayUnsubmitNotificationType =
  (typeof EssayUnsubmitNotificationType)[keyof typeof EssayUnsubmitNotificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EssayUnsubmitNotificationType = {
  EE_ESSAY: 'EE_ESSAY',
  TOK_ESSAY: 'TOK_ESSAY',
} as const;
