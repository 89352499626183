import { useState } from 'react';
import { useIBGetIBBycoordinator } from 'src/generated/endpoint';
import { IBGetIBBycoordinatorParams } from 'src/generated/model';

export function useGetIBProject() {
  const [param, setParam] = useState<IBGetIBBycoordinatorParams>();

  const { data, isLoading } = useIBGetIBBycoordinator(param, {
    query: {
      // enabled: !!param?.statuses,
    },
  });

  const getIBProject = ({
    grade,
    klass,
    ibTypes,
    limit,
    mentorId,
    page,
    proposalSubject,
    statuses,
    studentId,
    studentName,
  }: IBGetIBBycoordinatorParams) => {
    setParam({ grade, klass, ibTypes, limit, mentorId, page, proposalSubject, statuses, studentId, studentName });
  };

  return {
    data,
    getIBProject,
    isLoading,
  };
}
