import {
  useStudentExamScoreGetAnalysedScores,
  useStudentExamScoreGetAnalysedTargetScores,
  useStudentExamScorePatchStudentExamScores,
} from 'src/generated/endpoint';

export function useStudentAnalysisScore(studentId: number): { data: any; isLoading: boolean; error: any } {
  const { data, isLoading, error } = useStudentExamScoreGetAnalysedScores(studentId);
  return { data, isLoading, error };
}

export function useStudentAnalysisTargetScore(
  studentId: number,
  grade: number,
  semester: number,
): { data: any; isLoading: boolean } {
  //@ts-ignore
  const { data, isLoading } = useStudentExamScoreGetAnalysedTargetScores(studentId, { grade, semester });
  return { data, isLoading };
}

export function useTargetScoreAnalysis() {
  const { mutateAsync: patchTargetScoreAnalysisMutate, isLoading } = useStudentExamScorePatchStudentExamScores({});
  return {
    patchTargetScoreAnalysisMutate,
    isLoading,
  };
}
