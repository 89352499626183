import { t } from 'i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrow } from 'src/assets/svg/mypage-right-arrow.svg';
import { BackButton, Section, TopNavbar } from 'src/components/common';
import { Icon } from 'src/components/common/icons';
import { UserContainer } from 'src/container/user';

export function TeacherApplyPage() {
  const { push } = useHistory();
  const { me } = UserContainer.useContext();
  const [selectedFieldtrip, setSelectedFieldtrip] = useState(false);

  return (
    <>
      <div className="block md:hidden">
        <TopNavbar title="출결" left={<BackButton onClick={() => push('/')} />} />

        <Section>
          <div
            className="border-gray-6 flex cursor-pointer items-center justify-between border-b-2 py-3"
            onClick={() => push('/teacher/attendance')}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">출석부</div>
              <div className="text-sm text-grey-5">반별 출석상태 조회/관리</div>
            </div>
            <RightArrow />
          </div>
          <div
            className="border-gray-6 flex cursor-pointer items-center justify-between border-b-2 py-3"
            onClick={() => push('/teacher/timetable')}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">시간표/출석체크</div>
              <div className="text-sm text-grey-5">교사별/학급별 시간표 조회 및 출석체크</div>
            </div>
            <RightArrow />
          </div>
          <div
            className="border-gray-6 flex cursor-pointer items-center justify-between border-b-2 py-3"
            onClick={() => push('/teacher/outing')}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">확인증</div>
              <div className="text-sm text-grey-5">조퇴증,외출증,확인증 / 조퇴,외출,확인 전 작성 서류</div>
            </div>
            <RightArrow />
          </div>
          <div
            className="border-gray-6 flex cursor-pointer items-center justify-between border-b-2 py-3"
            onClick={() => push('/teacher/absent')}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">{t(`absentTitle`, '결석신고서')}</div>
              <div className="text-sm text-grey-5">구,결석계 / 조퇴,외출,결과,지각,결석 후 작성 서류</div>
            </div>
            <RightArrow />
          </div>
          <div
            className={`flex items-center justify-between pt-3 ${
              !selectedFieldtrip && 'border-b-2 pb-3'
            } border-gray-6 cursor-pointer`}
            onClick={() => setSelectedFieldtrip(!selectedFieldtrip)}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">체험학습</div>
              <div className="text-sm text-grey-5">신청,통보,결과보고서 / 체험학습 관련 작성 서류</div>
            </div>
            {selectedFieldtrip ? <Icon.ChevronDown /> : <RightArrow />}
          </div>
          {selectedFieldtrip && (
            <div className="border-gray-6 border-b-2 bg-gray-100">
              <div
                className="flex cursor-pointer items-center justify-between py-1"
                onClick={() => push('/teacher/fieldtrip')}
              >
                <div className="text-gray-1 font-sfpro pl-4 font-bold">신청서</div>
                <RightArrow />
              </div>

              <div
                className="flex cursor-pointer items-center justify-between py-1"
                onClick={() => push('/teacher/fieldtrip/notice')}
              >
                <div className="text-gray-1 font-sfpro pl-4 font-bold">통보서</div>
                <RightArrow />
              </div>

              <div
                className="flex cursor-pointer items-center justify-between py-1"
                onClick={() => push('/teacher/fieldtrip/result')}
              >
                <div className="text-gray-1 font-sfpro pl-4 font-bold">결과보고서</div>
                <RightArrow />
              </div>
            </div>
          )}

          <div
            className="border-gray-6 flex cursor-pointer items-center justify-between border-b-2 py-3"
            onClick={() => push('/teacher/studentcard')}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">학생정보</div>
              <div className="text-sm text-grey-5">학생 신상정보 관리, 상담카드</div>
            </div>
            <RightArrow />
          </div>
          <div
            className="border-gray-6 hidden cursor-pointer items-center justify-between border-b-2 py-3 md:flex"
            onClick={() => push('/teacher/history')}
          >
            <div>
              <div className="font-sfpro text-lg font-bold text-gray-800">출결서류관리</div>
              <div className="text-sm text-grey-5">{`학생증, ${t(
                `absentTitle`,
                '결석신고서',
              )}, 체험학습 이력관리`}</div>
            </div>
            <RightArrow />
          </div>
        </Section>
      </div>
    </>
  );
}
