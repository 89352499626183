import clsx from 'clsx';
import React, { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

// C-IB-021: IB 기본 레이아웃
// https://www.notion.so/superschoolofficial/C-IB-021-IB-8702e1b24cbe431685cd9ee702de3bd7

interface LayoutProps extends HTMLAttributes<HTMLElement> {
  topContent: ReactNode;
  bottomContent: ReactNode;
  topBgColor?: string;
  bottomBgColor?: string;
  hasContour?: boolean;
  floatingButton?: ReactNode;
}

const IBLayout: React.FC<LayoutProps> = ({
  topContent,
  bottomContent,
  topBgColor = '',
  bottomBgColor = '',
  floatingButton,
  className,
  hasContour = true,
  ...props
}: LayoutProps) => {
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [topHeight, setTopHeight] = useState(0);

  useEffect(() => {
    // 상단 영역의 높이를 계산
    if (topRef.current) {
      setTopHeight(topRef.current.offsetHeight);
    }

    // 윈도우가 리사이즈될 때 상단 영역의 높이를 재계산
    const handleResize = () => {
      if (topRef.current) {
        setTopHeight(topRef.current.offsetHeight);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const topElement = topRef.current;
    const bottomElement = bottomRef.current;

    if (topElement && bottomElement) {
      bottomElement.scrollTo({ top: 0, behavior: 'smooth' });
      const handleScroll = () => {
        if (topElement) {
          topElement.scrollLeft = bottomElement.scrollLeft;
        }
      };

      // 하단 스크롤 이벤트를 상단에 동기화
      bottomElement.addEventListener('scroll', handleScroll);

      return () => {
        bottomElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [bottomContent]);

  return (
    <div className={clsx('flex min-h-screen w-full justify-center', className)} {...props}>
      <div className="flex w-full flex-col">
        {/* 상단 영역 */}
        <div ref={topRef} className={clsx('scroll-box w-full overflow-x-auto', topBgColor)}>
          <div className="mx-auto w-[1280px]">{topContent}</div>
        </div>
        {hasContour && <div className="h-[1px] bg-gray-200"></div>}
        {/* 하단 영역 */}
        <div
          ref={bottomRef}
          className={clsx('scroll-box w-full overflow-x-auto overflow-y-auto', bottomBgColor, {
            'pb-40': floatingButton,
            'pb-20': !floatingButton,
          })}
          style={{ height: `calc(100vh - ${topHeight}px - 0.5px)` }}
        >
          <div className="mx-auto w-[1280px]">{bottomContent}</div>
        </div>
        {/* 하단 플로팅 영역 */}
        {floatingButton && (
          <div className="sticky bottom-0 left-0 z-50 flex w-full justify-center border-t border-primary-gray-100 bg-white/70 px-52 py-5 backdrop-blur-[20px]">
            <div className="flex w-full max-w-[1280px] justify-center">{floatingButton}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IBLayout;
