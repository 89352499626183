/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

/**
 * 평가 유형
 */
export type ResponseTokEvaluationCriteriaDtoType =
  (typeof ResponseTokEvaluationCriteriaDtoType)[keyof typeof ResponseTokEvaluationCriteriaDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResponseTokEvaluationCriteriaDtoType = {
  EXHIBITION: 'EXHIBITION',
  ESSAY: 'ESSAY',
} as const;
