import { useRecoilValue } from 'recoil';
import { useBlockChainGetDocumentStatus } from 'src/generated/endpoint';
import { BlockChainGetDocumentStatusParams } from 'src/generated/model';
import { meState } from 'src/store';

export function useBlockChainDocument({ referenceTable, referenceId }: BlockChainGetDocumentStatusParams) {
  const me = useRecoilValue(meState);
  const { data, isLoading } = useBlockChainGetDocumentStatus(
    { referenceTable, referenceId },
    { query: { enabled: me?.school.useBlockChain } },
  );

  return {
    data,
    isLoading,
  };
}
