import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrow } from 'src/assets/svg/mypage-right-arrow.svg';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, Section, TopNavbar } from 'src/components/common';
import { useStudentOuting } from 'src/container/student-outing';
import { UserContainer } from 'src/container/user';
import { makeDateToString } from 'src/util/time';

function getDate(stromg: string) {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[new Date(stromg).getDay()];
  return dayOfWeek + '요일';
}

export function OutingPage() {
  const { me } = UserContainer.useContext();
  const { push } = useHistory();
  const { outings, error, isLoading } = useStudentOuting();

  const isNotParent = me?.role !== 'PARENT';

  if (error) {
    return <ErrorBlank />;
  }

  return (
    <div>
      <TopNavbar
        title="확인증"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      {isLoading && <Blank />}
      {error && <ErrorBlank />}

      <div className="scroll-box h-screen-12 overflow-y-auto">
        <Section>
          <div className="space-y-4 pb-12">
            {outings?.map((outing) => {
              return (
                <div
                  key={outing.id}
                  className="flex cursor-pointer items-center justify-between border-b border-grey-9 pb-4"
                  onClick={() => push(`/student/outing/${outing?.id}`)}
                >
                  <div>
                    <div className="font-bold text-gray-800">
                      [{outing?.type}] {outing?.startAt && makeDateToString(new Date(outing.startAt))}
                      {makeDateToString(new Date(outing.startAt)) === makeDateToString(new Date(outing.endAt))
                        ? ' ' + getDate(outing?.startAt)
                        : ' ~ ' + makeDateToString(new Date(outing.endAt))}
                    </div>
                    <div className="text-sm text-gray-600">
                      {outing?.reportedAt}{' '}
                      {outing?.outingStatus === 'PROCESSED' ? (
                        '승인 완료'
                      ) : outing?.outingStatus === 'BEFORE_TEACHER_APPROVAL' ? (
                        <span className="font-bold text-blue-600">승인 전</span>
                      ) : outing?.outingStatus === 'DELETE_APPEAL' ? (
                        <span className="font-bold text-red-500">삭제 요청</span>
                      ) : (
                        <span className="font-bold text-brand-1">반려됨</span>
                      )}
                    </div>
                  </div>
                  <RightArrow />
                </div>
              );
            })}
            {!outings?.length && (
              <div className="flex h-screen-10 w-full items-center justify-center text-center">
                <div className="text-gray-600">
                  아직 조퇴/외출/확인증 리스트가 없습니다. <br />
                  아래 버튼을 눌러 신청해주세요.
                </div>
              </div>
            )}
          </div>
        </Section>
      </div>

      {isNotParent && (
        <div className="w-full px-4">
          <button
            children="확인증 신청하기"
            onClick={() => push('/student/outing/add')}
            className="h-14 w-full rounded-lg bg-brand-1 px-4 text-white"
          />
        </div>
      )}
    </div>
  );
}
