import { useTranslation } from 'react-i18next';
import { SuperModal } from 'src/components';
import { Label } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Time } from 'src/components/common/Time';
import {
  teacherPointLogDelete,
  useTeacherPointLogGetOne,
  useTeacherUserGetOne,
  useUserMe,
} from 'src/generated/endpoint';
import { numberWithSign } from 'src/util/string';
import { useModals } from './ModalStack';

export interface PointLogModalProps {
  pointLogId: number;
}

export function PointLogModal({ pointLogId }: PointLogModalProps) {
  const { popModal } = useModals();
  const { t } = useTranslation();
  const { t: tm } = useTranslation('modal', { keyPrefix: 'point_log_modal' });
  const { data: me } = useUserMe();

  const { data: pointLog } = useTeacherPointLogGetOne(pointLogId);
  const { data: teacher } = useTeacherUserGetOne(
    pointLog?.teacherId ?? 0,
    {},
    { query: { enabled: !!pointLog?.teacherId } },
  );

  async function deletePointLog() {
    if (!confirm(`기록을 삭제할까요?`)) return;
    await teacherPointLogDelete(pointLogId);
    popModal();
  }

  return (
    <SuperModal
      modalOpen
      setModalClose={popModal}
      className="flex w-96 flex-col gap-4 px-4 py-20 max-md:h-screen-3.5 max-md:w-full max-md:self-start max-md:rounded-none md:max-h-screen-48"
    >
      <h2 className="text-18">{tm('point_log')}</h2>
      <div>
        {me?.id === pointLog?.teacherId && (
          <Button children={t('delete')} onClick={deletePointLog} className="outlined-gray" />
        )}
      </div>
      <Label.col>
        <Label.Text children={tm('point_log_created_at')} />
        <p>
          <Time date={pointLog?.createdAt} className="text-16" />
        </p>
      </Label.col>
      <Label.col>
        <Label.Text children={tm('point_log_assigner')} />
        <p>{teacher?.name}</p>
      </Label.col>
      <Label.col>
        <Label.Text children={tm('point_log_title')} />
        <p>{pointLog?.title}</p>
      </Label.col>
      <Label.col>
        <Label.Text children={tm('point_log_value')} />
        <p>{numberWithSign(pointLog?.value)}</p>
      </Label.col>
    </SuperModal>
  );
}
