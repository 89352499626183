import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useRecoilValue } from 'recoil';
import SvgUser from 'src/assets/svg/user.svg';
import { SelectMenus, SuperModal } from 'src/components';
import { Divider, Label, Section, Select } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { Tabs } from 'src/components/common/Tabs';
import { TextInput } from 'src/components/common/TextInput';
import { Time } from 'src/components/common/Time';
import { TimetableAtdCard } from 'src/components/timetable/TimetableAtdCard';
import { TimetableStudentRole } from 'src/components/timetable/TimetableStudentRole';
import { Constants } from 'src/constants';
import { GroupContainer } from 'src/container/group';
import { useTeacherSeatPosition } from 'src/container/teacher-seat-position';
import { useTimeTableAttendancePageV3 } from 'src/container/teacher-timetable-attendance-page-v3';
import {
  LectureType,
  RequestUpsertStudentRoleDto,
  ResponseTimetableV3Dto,
  ResponseUserAttendanceDto,
} from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { useModals } from 'src/modals/ModalStack';
import { StudentModal } from 'src/modals/StudentModal';
import { meState } from 'src/store';
import { dayOfEngWeek, dayOfKorWeek } from 'src/util/date';
import { getNickName } from 'src/util/status';
import { getThisSemester, getThisYear } from 'src/util/time';
import { TimetableNeisForm } from './TimetableNeisForm';

const groups = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
];

enum contentType {
  list = 1,
  seat,
  role,
  neis,
}

enum listType {
  total = 1,
  in,
  out,
  del,
}

interface AbsentUser {
  id: number;
  profile: string;
  klassname: string;
  student_number: string;
  name: string;
  nick_name?: string;
  hope: string;
  major: string;
  absent: boolean;
  content: string;
  comment: string;
  type1: string;
  type2: string;
  role: string;
  job: string;
}

const defaultSelectedUser = {
  id: -1,
  profile: '',
  klassname: '',
  student_number: '',
  name: '',
  hope: '',
  major: '',
  absent: false,
  content: '',
  comment: '',
  type1: '',
  type2: '',
  role: '',
  job: '',
};

interface SeatType2 {
  id: number;
  name: string;
}

export interface AttendanceContent {
  absent: boolean;
  comment: string;
  createtime: string;
  creator: string;
  editor: string;
  edittime: string;
  period: number;
  subject: string;
  type1: string;
  type2: string;
}

interface RoleInfoType {
  id: number;
  klassname: string;
  student_number: number;
  name: string;
  role: string;
  job: string;
  displayOrder: number;
}

const getTargetDay = (dayOfWeek: number) => {
  const currentDate = new Date();
  const startOfWeek = moment(currentDate).startOf('week').add(1, 'day');
  const datesOfWeek = [];
  let tempDate = startOfWeek;
  while (datesOfWeek.length <= 7) {
    datesOfWeek.push(tempDate.format('YYYY-MM-DD'));
    tempDate = moment(tempDate).add(1, 'day');
  }

  return datesOfWeek[dayOfWeek - 1];
};

interface TimetableAttendancePageProps {
  lectureInfo: ResponseTimetableV3Dto;
}

export function TimetableAttendancePage({ lectureInfo }: TimetableAttendancePageProps) {
  const year = +getThisYear();
  const semester = +getThisSemester();
  const { t, currentLang } = useLanguage();
  const { pushModal } = useModals();

  const me = useRecoilValue(meState);
  const lastPeriod = me?.school.lastPeriod || 8;

  const { allKlassGroups } = GroupContainer.useContext();
  const selectedKlassInfo = allKlassGroups.find((item) => item.name === lectureInfo.groupName);

  const myKlass = selectedKlassInfo?.homeRoomTeacherId === me?.id;

  // klassInfo.timeCode = 요일_몇교시 ex: "monday_1", "wednesday_3"
  // const [dayOfWeek, selectedPeriodString] = selectedLectureInfo;
  const targetDay = getTargetDay(lectureInfo.day);
  const selectedPeriod = lectureInfo.time;

  const [teacherName, setTeacherName] = useState(selectedKlassInfo?.homeRoomTeacherName);
  const teacherNickName = getNickName(selectedKlassInfo?.homeRoomTeacherNickName);

  useEffect(() => {
    setTeacherName(selectedKlassInfo ? selectedKlassInfo?.homeRoomTeacherName : lectureInfo.teacherName);
  }, [selectedKlassInfo]);

  const { seatPositionId, seatPosition, updateSeatPosition } = useTeacherSeatPosition({
    groupId: selectedKlassInfo ? selectedKlassInfo.id : lectureInfo.groupId,
  });

  const { userAttendance, createAttendanceAbsent, updateStudentRole, AttendanceCheckInfo, createAttendanceCheck } =
    useTimeTableAttendancePageV3({
      lectureId: lectureInfo.id,
      groupId: lectureInfo.groupId.toString() || '0',
      year: year.toString(),
      day: targetDay,
    });

  const students: ResponseUserAttendanceDto[] = userAttendance; // me 데이터와 유사

  const tempRoleInfo = useMemo(() => {
    return userAttendance
      .filter((student: any) => !student.expired)
      .map((student: any) => ({
        id: student.id,
        klassname: student.klassname as string,
        student_number: student.student_number as number,
        name: student.name,
        role: student.role ? student.role : '',
        job: student.job ? student.job : '',
        displayOrder: student?.display_order ? student.display_order : 0,
      }))
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }, [userAttendance]);

  const [roleInfo, setRoleInfo] = useState<RoleInfoType[]>([...tempRoleInfo]);

  const [showAbsent, setShowAbsent] = useState(listType.total);

  const [showSeat, setShowSeat] = useState(contentType.list);
  const [selectedUserId, setSelectedUserId] = useState<AbsentUser>(defaultSelectedUser);
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);

  const [seatSizeRows, setSeatSizeRows] = useState<SeatType2>({ id: 0, name: '0' });
  const [seatSizeCols, setSeatSizeCols] = useState<SeatType2>({ id: 6, name: '6' });

  const [seatEditMode, setSeatEditMode] = useState(false);
  const [roleEditMode, setRoleEditMode] = useState(false);

  const [showSubject, setShowSubject] = useState(false);

  const removeStudents = new Map<number, boolean>();
  const absentOfSelectedPeriod = new Map<number, boolean>();
  const absentCommentOfSelectedPeriod = new Map<number, string>();

  const studentsAbsent: Array<number> = [];
  if (students) {
    students.map((student: ResponseUserAttendanceDto) => {
      if (student.expired) {
        removeStudents.set(student.id, true);
        absentCommentOfSelectedPeriod.set(student.id, '');
      } else {
        if (student.content) {
          const contentJson: { attendance: AttendanceContent[] } = JSON.parse(student.content);

          absentOfSelectedPeriod.set(
            student.id,
            contentJson.attendance.length > lectureInfo.time ? contentJson.attendance[lectureInfo.time].absent : false,
          );
          absentCommentOfSelectedPeriod.set(
            student.id,
            contentJson.attendance.length > lectureInfo.time ? contentJson.attendance[lectureInfo.time].comment : '',
          );

          if (contentJson.attendance.length > lectureInfo.time && contentJson.attendance[lectureInfo.time].absent) {
            student.student_number && studentsAbsent.push(student.student_number);
          }

          if (
            contentJson.attendance.length > lectureInfo.time &&
            contentJson.attendance[lectureInfo.time].type1 !== '' &&
            contentJson.attendance[lectureInfo.time].type2 !== ''
          ) {
            student.type1 = contentJson.attendance[lectureInfo.time].type1;
            student.type2 = contentJson.attendance[lectureInfo.time].type2;
          }
        } else {
          absentOfSelectedPeriod.set(student.id, false);
          absentCommentOfSelectedPeriod.set(student.id, '');
        }
      }
    });
  }

  const handleModalOpen = (student: any) => {
    setSelectedUserId({
      id: student.id,
      profile: student.profile,
      klassname: student.klassname || '',
      student_number: student.student_number ? student.student_number.toString() : '',
      name: student.name,
      nick_name: student.nick_name,
      hope: student.hopepath,
      major: student.hopemajor,
      absent: absentOfSelectedPeriod.get(student.id) ? true : false,
      content: student.content || '',
      comment: absentCommentOfSelectedPeriod.get(student?.id) || '',
      type1: student.type1 || '',
      type2: student.type2 || '',
      role: student?.role || '',
      job: student?.job || '',
    });
    setIsAttendanceModalOpen(true);
  };

  const getAttendanceArray = () => {
    let contentJson: { attendance: AttendanceContent[] } = { attendance: [] };
    // 기초데이터 생성
    if (selectedUserId.content) {
      contentJson = JSON.parse(selectedUserId.content) as { attendance: AttendanceContent[] };
    } else {
      for (let i = 0; i <= lastPeriod; i++) {
        const t = {
          subject: i === 0 ? '조회' : '',
          period: i,
          creator: me?.name ? me?.name : '',
          createtime: new Date().toLocaleString(),
          editor: '',
          edittime: '',
          comment: '',
          absent: i < selectedPeriod ? false : selectedUserId.absent, // 교과반에서 수정시에는 이후시간에 출결만 반영
          type1: '',
          type2: '',
        };

        contentJson.attendance.push(t);
      }
    }
    return contentJson;
  };

  const confirmAttendanceCheck = () => {
    const result = confirm(
      `${lectureInfo.room} ${dayOfKorWeek(lectureInfo.day)}요일 ${
        lectureInfo.time === 0 ? '조회' : lectureInfo.time + '교시'
      } 의 출석체크를 하셨습니까? `,
    );

    if (result) {
      createAttendanceCheck();
    }
  };

  /// userId : 결석처리할 학색,
  /// absent : true : 미출석, false : 출석
  /// content : 이전시간 출석 기록
  const submitAbsentUser = (submit: boolean) => {
    if (!submit || selectedUserId.id <= 0) {
      setIsAttendanceModalOpen(false);
      return;
    }

    const contentJson = getAttendanceArray();

    let type1 = '';
    let type2 = '';

    if (selectedUserId.absent) {
      type1 = !selectedUserId.type1 || selectedUserId.type1 === '' ? '기타' : selectedUserId.type1;
      type2 = !selectedUserId.type2 || selectedUserId.type2 === '' ? '기타' : selectedUserId.type2;
    }

    contentJson.attendance[selectedPeriod].subject = lectureInfo.subject;
    contentJson.attendance[selectedPeriod].comment = selectedUserId.comment || '';
    contentJson.attendance[selectedPeriod].type1 = type1;
    contentJson.attendance[selectedPeriod].type2 = type2;
    contentJson.attendance[selectedPeriod].editor = me?.name ? me?.name : '';
    contentJson.attendance[selectedPeriod].edittime = new Date().toLocaleString();
    contentJson.attendance[selectedPeriod].absent = selectedUserId.absent;

    // 선택한 다음시간부터  동일한 상태로 만든다.
    if (type1 !== '결과') {
      // 결과는 다음시간에 영향을 미치지 않음.
      for (let i = selectedPeriod + 1; i <= lastPeriod; i++) {
        contentJson.attendance[i].type1 = type1;
        contentJson.attendance[i].type2 = type2;
        contentJson.attendance[i].absent = selectedUserId.absent;
      }
    }

    createAttendanceAbsent({
      attendanceDay: targetDay,
      absent: selectedUserId.absent,
      comment: selectedUserId.comment || '',
      type1: type1,
      type2: type2,
      content: JSON.stringify(contentJson),
      year: year,
      semester: semester,
      userId: selectedUserId.id,
      schoolId: me?.schoolId ? me.schoolId : 0,
      sendNoti: me?.name === teacherName,
      notiType: 'simple',
    })
      .then(() => setIsAttendanceModalOpen(false))
      .then(() => setSelectedUserId(defaultSelectedUser));
  };

  let seatPositionMap: { studentid: number; seat: string }[] = seatPosition;

  let maxCol = 6;
  let maxRow = 0;

  // 설정된 자리 크기를 가져온다. to-do
  if (seatPositionMap.length > 0) {
    let tmpRow = 1;
    let tmpCol = 1;
    seatPositionMap.map((seatInfo: { studentid: number; seat: string }) => {
      const row = Math.floor(Number(seatInfo?.seat) / 10);
      const col = Number(seatInfo?.seat) % 10;

      tmpRow = row > tmpRow ? row : tmpRow;
      tmpCol = col > tmpCol ? col : tmpCol;
    });

    maxCol = tmpCol + 1;
    maxRow = tmpRow;
  }

  let newSeatCount = 0;
  // 누락된 학생 찾아서 넣기 start
  const getNewSeat = () => {
    const newRow = maxRow + Math.floor(newSeatCount / maxCol);
    const newCol = newSeatCount % maxCol;

    newSeatCount++;
    return newRow.toString() + newCol.toString();
  };

  students?.map((student: ResponseUserAttendanceDto) => {
    if (!student.expired) {
      const rst = seatPositionMap?.find(
        (seatinfo: { studentid: number; seat: string }) => seatinfo.studentid === student.id,
      );
      if (rst === undefined) {
        seatPositionMap.push({
          studentid: student.id,
          seat: `${getNewSeat()}`,
        });
      }
    }
  });
  maxRow = maxRow + Math.floor(newSeatCount / maxCol);

  // dom 에서 자리의 학생 가져오기
  const seatSudentMap = new Map<string, any>();
  const getStudentSeat = (row: number, col: number): ResponseUserAttendanceDto | undefined => {
    const seat = row.toString() + col.toString();

    let rststudent: ResponseUserAttendanceDto | undefined;

    if (!seatSudentMap.has(seat)) {
      const rst = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === seat);
      const studentId = rst?.studentid;

      rststudent = students?.find((student: any) => student.id === studentId);

      if (rststudent?.expired) {
        rststudent = undefined;
      }

      if (rststudent) {
        seatSudentMap.set(seat, rststudent);
      } else {
        // 자리설정은 되었지만 삭제된 학생
        seatPositionMap = seatPositionMap.filter((obj: any) => {
          return obj !== rst;
        });
      }
    } else {
      rststudent = seatSudentMap.get(seat);
    }

    return rststudent;
  };

  const [selSeat, setSelSeat] = useState('');

  const swapSeat = (row: number, col: number) => {
    const seat = row.toString() + col.toString();

    if (selSeat === '') {
      setSelSeat(seat);
    } else {
      // selSeat 와 seat 변경
      const selInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === selSeat);
      const nowInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === seat);

      if (selInfo !== undefined) selInfo.seat = seat;
      if (nowInfo !== undefined) nowInfo.seat = selSeat;

      saveStudentSeat();
      setSelSeat('');
    }
  };

  const swapSeatDrop = (selSeat: string, nowSeat: string) => {
    // selSeat 와 seat 변경
    const selInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === selSeat);
    const nowInfo = seatPositionMap?.find((seatinfo: any) => seatinfo.seat === nowSeat);

    if (selInfo !== undefined) selInfo.seat = nowSeat;
    if (nowInfo !== undefined) nowInfo.seat = selSeat;

    saveStudentSeat();
  };

  const saveStudentSeat = () => {
    if (seatEditMode) {
      const seatPosition = JSON.stringify(seatPositionMap);
      updateSeatPosition({ id: seatPositionId || 0, seatPosition });
    }
  };

  const checkSeatSize = (isCol: boolean, size: number) => {
    if ((isCol && maxCol > size) || (!isCol && maxRow + 1 > size)) {
      alert('학생이 설정되어 있는 자리는 삭제할 수 없습니다.');
      return false;
    } else {
      return true;
    }
  };

  const saveRole = () => {
    if (!roleEditMode) {
      return;
    }

    const roleInfos: RequestUpsertStudentRoleDto[] = roleInfo.map((student: RoleInfoType, i: number) => {
      return {
        userId: student.id,
        role: student.role,
        job: student.job,
        displayOrder: i,
        year: year,
      };
    });

    updateStudentRole(roleInfos);
  };

  let from = -1;

  const disabledSaveButton = false;
  // selectedUserId.absent
  //   ? !selectedUserId.type1 || !selectedUserId.type2 //|| !selectedUserId.comment
  //   : false;

  useEffect(() => {
    if (maxRow >= 0) {
      setSeatSizeRows(groups[maxRow]);
      setSeatSizeCols(groups[maxCol - 1]); // 인덱스가 0부터 시작하므로,
    }
  }, [maxRow]);

  useEffect(() => {
    setRoleEditMode(false);
    setRoleInfo(tempRoleInfo);
  }, [students]);

  return (
    <div className="scroll-box h-screen-8 overflow-y-auto md:h-screen">
      <div>
        <div className="flex flex-wrap justify-between">
          <div>{lectureInfo.room}</div>
          <div>{targetDay}</div>
        </div>
        <div className="my-3 flex flex-wrap justify-between font-semibold md:text-xl">
          <p className="flex flex-wrap">
            {currentLang === 'ko' ? <>{dayOfKorWeek(lectureInfo.day)}요일 </> : <>{dayOfEngWeek(lectureInfo.day)} </>}
            {lectureInfo.time === 0 ? (
              '조회'
            ) : (
              <>
                {' '}
                {lectureInfo.time + '교시'} |{' '}
                {lectureInfo.type === LectureType.SELECT ? (
                  <>
                    <div className="ml-2">분반</div>
                    <div
                      className="mx-2 rounded bg-blue-100 px-2.5 text-sm text-blue-600 md:text-base"
                      onClick={() => setShowSubject(!showSubject)}
                    >
                      과목보기
                    </div>
                  </>
                ) : (
                  <>
                    {lectureInfo.subject}{' '}
                    {(lectureInfo.type === LectureType.FIX || lectureInfo.type === LectureType.MOVE) &&
                      '| ' + lectureInfo.teacherName + getNickName(lectureInfo?.teacherNickName)}
                  </>
                )}{' '}
              </>
            )}
          </p>
          <p className="flex-shrink-0">
            {teacherName
              ? `${t('supervisor', '담당')} : ${teacherName}${teacherNickName} ${t('teacher', '선생님')}`
              : ''}
          </p>
        </div>
        {showSubject && lectureInfo.type === LectureType.SELECT && (
          <div className="bg-grey-100 mb-3 flex items-center justify-between rounded-lg border px-5">
            {lectureInfo.info}
          </div>
        )}
        <div className="bg-grey-100 flex flex-wrap items-center justify-between rounded-lg border p-5">
          <p
            className={clsx(
              'flex cursor-pointer flex-wrap',
              showAbsent === listType.total && 'font-extrabold text-red-500',
            )}
            onClick={() => setShowAbsent(listType.total)}
          >
            {t('total_students', '총원')} : {students?.length - removeStudents.size} {t('count', '명')}
          </p>
          <p
            className={clsx(
              'flex cursor-pointer flex-wrap',
              showAbsent === listType.in && 'font-extrabold text-red-500',
            )}
            onClick={() => setShowAbsent(listType.in)}
          >
            {t('attendance', '출석')} : {students?.length - removeStudents.size - studentsAbsent?.length}{' '}
            {t('count', '명')}
          </p>
          <p
            className={clsx(
              'flex cursor-pointer flex-wrap',
              showAbsent === listType.out && 'font-extrabold text-red-500',
            )}
            onClick={() => setShowAbsent(listType.out)}
          >
            {t('non-attendance', '미출석')} : {studentsAbsent?.length} {t('count', '명')}
          </p>
          <p
            className={clsx(
              'hidden cursor-pointer md:inline',
              showAbsent === listType.del && 'font-extrabold text-red-500',
            )}
            onClick={() => setShowAbsent(listType.del)}
          >
            {t('expelled', '제적')}
          </p>
        </div>
      </div>
      <Tabs>
        {[
          { name: t('list', '목록'), type: contentType.list },
          { name: t('seats', '자리'), type: contentType.seat },
          { name: t('daily_report', '일일현황'), type: contentType.neis },
          { name: t('one_person_one_seat', '일인일역'), type: contentType.role },
        ].map((tab) => (
          <Tabs.Button
            key={tab.name}
            children={tab.name}
            selected={showSeat === tab.type}
            onClick={() => {
              setSeatEditMode(false);
              setRoleEditMode(false);
              setShowSeat(tab.type);
            }}
            className="md:flex-none md:px-5"
          />
        ))}
      </Tabs>

      <div className="md:scroll-box md:h-screen-13 md:overflow-y-auto md:overflow-x-hidden">
        {/* 출석부 tab */}
        {showSeat === contentType.list && (
          <div className="mb-10">
            {students
              ?.filter((student: ResponseUserAttendanceDto) =>
                showAbsent === listType.in
                  ? removeStudents.get(student.id) === false || absentOfSelectedPeriod.get(student.id) === false
                  : showAbsent === listType.out
                    ? absentOfSelectedPeriod.get(student.id)
                    : showAbsent === listType.del
                      ? removeStudents.get(student.id)
                      : true,
              )
              .sort((a, b) => {
                const aData = a?.klassname?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
                const bData = b?.klassname?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

                if (!aData || !bData) {
                  return 0;
                }

                if (aData[1] && bData[1]) {
                  if (aData[1] === bData[1]) {
                    return Number(aData[2]) - Number(bData[2]);
                  } else {
                    return Number(aData[1]) - Number(bData[1]);
                  }
                } else {
                  return 0;
                }
              })
              .map((student: ResponseUserAttendanceDto, i: number) => (
                <TimetableAtdCard
                  key={i}
                  student={student}
                  comment={absentCommentOfSelectedPeriod.get(student.id) || undefined}
                  attendance={!absentOfSelectedPeriod.get(student.id)}
                  setModalOpen={() => handleModalOpen(student)}
                />
              ))}
            {(lectureInfo.time > 0 ||
              // && selectedLectureInfo.teacherId === me?.id
              (lectureInfo.time === 0 && teacherName === me?.name)) && (
              <div className="flex w-full flex-col items-center justify-center">
                <div className="mt-6 cursor-pointer">
                  {AttendanceCheckInfo ? (
                    <div className="mt-3 text-brandblue-1">
                      출석체크 완료 : {AttendanceCheckInfo.teacherName} (
                      <Time date={AttendanceCheckInfo.updatedAt} className="text-16 text-inherit" />)
                    </div>
                  ) : (
                    <Button.xl
                      children={t('check_attendance', '출석체크 확인')}
                      onClick={() => confirmAttendanceCheck()}
                      className="filled-primary"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {showSeat === contentType.neis && (
          <div className="scroll-box overflow-x-auto">
            <TimetableNeisForm students={userAttendance} lastPeriod={me?.school.lastPeriod || 8} />
          </div>
        )}

        {/* 자리 tab */}
        {showSeat === contentType.seat && (
          <div className="scroll-box overflow-x-auto">
            <table className="mx-auto border-separate py-4 text-center" style={{ borderSpacing: '0.5rem 0.5rem' }}>
              <tbody>
                {Array.from(Array(seatSizeRows.id).keys())
                  .reverse()
                  .map((r: number) => (
                    <tr key={r}>
                      {Array(seatSizeCols.id)
                        .fill(0)
                        .map((_: any, c: number) => (
                          <td
                            key={c}
                            className={`border border-grey-7 ${
                              selSeat === r.toString() + c.toString()
                                ? 'bg-blue-300'
                                : getStudentSeat(r, c)?.absent
                                  ? absentOfSelectedPeriod.get(getStudentSeat(r, c)?.id || 0)
                                    ? 'bg-red-300'
                                    : 'bg-white'
                                  : 'bg-grey-6'
                            } min-h-24 h-24 w-16 cursor-pointer rounded-md ${seatEditMode ? '' : 'not-draggable'}`}
                            draggable
                            onClick={() => {
                              if (seatEditMode) {
                                swapSeat(r, c);
                              } else {
                                const student = getStudentSeat(r, c);
                                student && handleModalOpen(student);
                              }
                            }}
                            onDragStart={(ev) => {
                              if (seatEditMode) {
                                ev.dataTransfer.setData('location', r.toString() + c.toString());
                              }
                            }}
                            onDrop={(ev) => {
                              if (seatEditMode) {
                                ev.preventDefault();
                                swapSeatDrop(ev.dataTransfer.getData('location'), r.toString() + c.toString());
                              }
                            }}
                            onDragOver={(ev) => {
                              if (seatEditMode) {
                                ev.preventDefault();
                              }
                            }}
                          >
                            {getStudentSeat(r, c) && (
                              <>
                                <p
                                  className="z-10 h-16 w-16 rounded-md bg-white bg-cover bg-top bg-no-repeat"
                                  // style={{
                                  //   backgroundImage: `url(${Constants.imageUrl}${getStudentSeat(r, c)?.profile})`,
                                  // }}
                                >
                                  <LazyLoadImage
                                    src={`${Constants.imageUrl}${getStudentSeat(r, c)?.profile}`}
                                    alt=""
                                    loading="lazy"
                                    className="h-full w-full rounded object-cover"
                                    onError={({ currentTarget }) => {
                                      currentTarget.src = SvgUser;
                                    }}
                                  />
                                </p>
                                <p>{getStudentSeat(r, c)?.name}</p>
                              </>
                            )}
                            {!getStudentSeat(r, c) && <p className="w-16"></p>}
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>

            <br />
            <div className="mb-6 flex w-full items-center justify-center space-x-2">
              <div className="rounded-md border border-grey-7 bg-grey-6 px-3 py-2">교탁</div>
            </div>

            {me?.name === teacherName ? (
              <div className="flex w-full flex-col items-center justify-center">
                {seatEditMode && (
                  <div className="flex items-center space-x-2">
                    <div className="flex min-w-max cursor-pointer items-center space-x-2">
                      <SelectMenus
                        allText="열 선택"
                        items={groups}
                        onChange={(e) => checkSeatSize(true, e.id) && setSeatSizeCols(e)}
                        value={seatSizeCols}
                      ></SelectMenus>
                      <div>열</div>
                    </div>

                    <div className="flex min-w-max cursor-pointer items-center space-x-2">
                      <SelectMenus
                        allText="줄 선택"
                        items={groups}
                        onChange={(e) => checkSeatSize(false, e.id) && setSeatSizeRows(e)}
                        value={seatSizeRows}
                      ></SelectMenus>
                      <div>줄</div>
                    </div>
                  </div>
                )}

                {me?.name === teacherName && (
                  <div className="mt-6 cursor-pointer">
                    <Button.xl
                      children={seatEditMode ? '변경완료' : '자리변경'}
                      onClick={() => {
                        setSelSeat('');
                        setSeatEditMode(!seatEditMode);
                      }}
                      className="filled-primary"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center">* 담임선생님만 수정이 가능합니다.</div>
            )}
            <br />
            <br />
          </div>
        )}

        {/* 일인일역 tab */}
        {showSeat === contentType.role && (
          <>
            <div className="-mx-4 mb-10">
              {roleInfo?.map((student: RoleInfoType, i: number) => (
                <div
                  key={i}
                  data-id={i}
                  draggable={roleEditMode}
                  className="w-full cursor-pointer"
                  onDragStart={(e) => {
                    if (roleEditMode) {
                      const item = e.currentTarget;
                      from = Number(item.dataset.id);
                    }
                  }}
                  onDrop={(e) => {
                    if (roleEditMode) {
                      if (from >= 0) {
                        const item = e.currentTarget;
                        const to = Number(item.dataset.id);

                        const tempList = roleInfo;

                        tempList.splice(to, 0, tempList.splice(from, 1)[0]);
                        setRoleInfo(tempList?.map((el) => el));
                      }
                    }
                  }}
                  onDragOver={(ev) => {
                    if (roleEditMode) {
                      ev.preventDefault();
                    }
                  }}
                >
                  <TimetableStudentRole
                    student={student}
                    editmode={roleEditMode}
                    order={i}
                    setOrder={(order: number, isUpDir: boolean) => {
                      const from = order;
                      const to = isUpDir ? from - 1 : from + 1;

                      if (to >= 0) {
                        const tempList = roleInfo;

                        tempList.splice(to, 0, tempList.splice(from, 1)[0]);
                        setRoleInfo(tempList?.map((el) => el));
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="flex w-full flex-col items-center justify-center">
              {myKlass && (
                <div className="mt-6 cursor-pointer">
                  <Button.xl
                    children={roleEditMode ? '저장하기' : '수정하기'}
                    onClick={() => {
                      if (roleEditMode) saveRole();
                      setRoleEditMode(!roleEditMode);
                    }}
                    className="filled-primary"
                  />
                </div>
              )}
              {!myKlass && '* 담임선생님만 수정이 가능합니다. '}
            </div>
            <br />
            <br />
          </>
        )}
      </div>
      {/* 상태변경 */}

      <SuperModal modalOpen={isAttendanceModalOpen} setModalClose={() => submitAbsentUser(false)} ablePropragation>
        <Section className="space-y-2">
          <div className="text-lg font-semibold">출결관리</div>
          <div className="flex h-40">
            <div className="w-2/5 rounded-md bg-white bg-cover bg-no-repeat">
              <LazyLoadImage
                src={`${Constants.imageUrl}${selectedUserId.profile}`}
                alt=""
                loading="lazy"
                className="h-full w-full rounded object-cover"
                onError={({ currentTarget }) => {
                  currentTarget.src = SvgUser;
                }}
              />
            </div>
            <div className="ml-2 w-3/5">
              <div className="text-lg font-bold">
                {selectedUserId.klassname} {selectedUserId.student_number}번
              </div>
              <div className="truncate text-lg font-bold">
                <button onClick={() => pushModal(<StudentModal id={selectedUserId.id} />)}>
                  {selectedUserId.name}
                  {getNickName(selectedUserId.nick_name)}
                </button>
              </div>
              <div className="text-sm">{selectedUserId.role}</div>
              <div className="text-sm">{selectedUserId.job}</div>
              <div className="text-sm text-grey-2">희망학과 | {selectedUserId.major}</div>
              <div className="text-sm text-grey-2">장래희망 | {selectedUserId.hope}</div>
            </div>
          </div>
          <Divider />
          {/* 출결관리 모달의 버튼 */}
          <div className="flex w-full items-center justify-between space-x-2">
            <Button
              children="출석"
              onClick={() =>
                setSelectedUserId((prevState) => ({ ...prevState, type1: '', type2: '', comment: '', absent: false }))
              }
              className={clsx('w-full', selectedUserId.absent ? 'bg-gray-100 text-gray-500' : 'filled-blue')}
            />
            <Button
              children="미출석"
              onClick={() => setSelectedUserId((prevState) => ({ ...prevState, absent: true }))}
              className={clsx('w-full', selectedUserId.absent ? 'filled-red-light' : 'bg-gray-100 text-gray-500')}
            />
          </div>
          <Label.col>
            <Label.Text children="사유" />
            <TextInput
              placeholder="특기사항을 입력해주세요."
              value={selectedUserId.comment}
              onChange={(e) => setSelectedUserId((prev) => ({ ...prev, comment: e.target.value }))}
            />
          </Label.col>
          <div className="flex space-x-2">
            <Label.col className="flex-1">
              <Label.Text children="신고유형" />
              <Select.lg
                value={selectedUserId.type2}
                disabled={!selectedUserId.absent}
                onChange={(e) => setSelectedUserId({ ...selectedUserId, type2: e.target.value })}
              >
                <option selected hidden>
                  구분
                </option>
                {['인정', '질병', '미인정', '기타'].map((subject: string) => (
                  <option value={subject} key={subject}>
                    {subject}
                  </option>
                ))}
              </Select.lg>
            </Label.col>
            <Label.col className="flex-1">
              <Label.Text children="구분" />
              <Select.lg
                value={selectedUserId.type1}
                disabled={!selectedUserId.absent}
                onChange={(e) => setSelectedUserId({ ...selectedUserId, type1: e.target.value })}
              >
                <option selected hidden>
                  유형
                </option>
                {['결석', '지각', '조퇴', '결과', '기타'].map((subject: string) => (
                  <option value={subject} key={subject}>
                    {subject}
                  </option>
                ))}
              </Select.lg>
            </Label.col>
          </div>
          <div className={'text-sm'}>미출석 시 신고유형 / 구분 필수</div>
          <Button
            children="저장하기"
            onClick={() => submitAbsentUser(true)}
            disabled={disabledSaveButton}
            className="filled-primary"
          />
          {me?.name === teacherName && (
            <div className="text-xs text-red-500">* 저장하면 보호자에게 출결상태 알림이 갑니다.</div>
          )}
        </Section>
      </SuperModal>
    </div>
  );
}
