/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

/**
 * 상세 상태
 */
export type ResponseIBProposalSubmissionStatusDtoDetailStatus =
  (typeof ResponseIBProposalSubmissionStatusDtoDetailStatus)[keyof typeof ResponseIBProposalSubmissionStatusDtoDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResponseIBProposalSubmissionStatusDtoDetailStatus = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMIT: 'SUBMIT',
  REJECT_PLAN: 'REJECT_PLAN',
  APPROVED: 'APPROVED',
} as const;
