/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type DeadlineType = (typeof DeadlineType)[keyof typeof DeadlineType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeadlineType = {
  EE_ESSAY: 'EE_ESSAY',
  EE_RRS: 'EE_RRS',
  EE_PROPOSAL: 'EE_PROPOSAL',
  EE_RPPF_1: 'EE_RPPF_1',
  EE_RPPF_2: 'EE_RPPF_2',
  EE_RPPF_3: 'EE_RPPF_3',
  TOK_TKPPF_1: 'TOK_TKPPF_1',
  TOK_TKPPF_2: 'TOK_TKPPF_2',
  TOK_TKPPF_3: 'TOK_TKPPF_3',
  TOK_MOCK_EXHIBITION: 'TOK_MOCK_EXHIBITION',
  TOK_FINAL_EXHIBITION: 'TOK_FINAL_EXHIBITION',
  TOK_EXHIBITION_PLAN: 'TOK_EXHIBITION_PLAN',
  TOK_ESSAY_OUTLINE: 'TOK_ESSAY_OUTLINE',
  TOK_ESSAY: 'TOK_ESSAY',
  TOK_RRS: 'TOK_RRS',
  CAS_INTERVIEW_1: 'CAS_INTERVIEW_1',
  CAS_INTERVIEW_2: 'CAS_INTERVIEW_2',
  CAS_INTERVIEW_3: 'CAS_INTERVIEW_3',
  CAS_REFLECTION_DIARY: 'CAS_REFLECTION_DIARY',
  CAS_ACTIVITY_LOG: 'CAS_ACTIVITY_LOG',
  CAS_PLAN: 'CAS_PLAN',
} as const;
