/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type ThemeQuestionGetThemeQuestionItemsByTypeType =
  (typeof ThemeQuestionGetThemeQuestionItemsByTypeType)[keyof typeof ThemeQuestionGetThemeQuestionItemsByTypeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThemeQuestionGetThemeQuestionItemsByTypeType = {
  TOK_ESSAY: 'TOK_ESSAY',
  TOK_EXHIBITION: 'TOK_EXHIBITION',
} as const;
