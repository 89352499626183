/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type CourseStatus = (typeof CourseStatus)[keyof typeof CourseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseStatus = {
  EDITING: 'EDITING',
  PROPOSED: 'PROPOSED',
  REJECTED: 'REJECTED',
  PRE_CONFIRMED: 'PRE_CONFIRMED',
  CONFIRMED: 'CONFIRMED',
  POSTED: 'POSTED',
  APPLY_ENDED: 'APPLY_ENDED',
  EXTRA_APPLY_STARTED: 'EXTRA_APPLY_STARTED',
  EXTRA_APPLY_ENDED: 'EXTRA_APPLY_ENDED',
  CANCELED: 'CANCELED',
  OPENED: 'OPENED',
  IN_LECTURE: 'IN_LECTURE',
  LECTURE_ENDED: 'LECTURE_ENDED',
  CLOSED: 'CLOSED',
} as const;
