/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

/**
 * IB 프로젝트 타입
 */
export type ResponseIBDtoIbType = (typeof ResponseIBDtoIbType)[keyof typeof ResponseIBDtoIbType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResponseIBDtoIbType = {
  NORMAL: 'NORMAL',
  CAS_NORMAL: 'CAS_NORMAL',
  CAS_PROJECT: 'CAS_PROJECT',
  EE: 'EE',
  TOK_ESSAY: 'TOK_ESSAY',
  TOK_EXHIBITION: 'TOK_EXHIBITION',
} as const;
