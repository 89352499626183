import { useParams } from 'react-router-dom';
import { ErrorBlank } from 'src/components/ErrorBlank';
import { useGetPlagiarismInspectDetail } from 'src/container/plagiarism-inspector';
import { Blank } from 'src/components/common';
import ExternalHTML from 'src/components/common/ExternalHTML';

export default function DetailResultPopup() {
  const { id: idParam } = useParams<{ id: string }>();
  const id = Number(idParam);

  const { data, isLoading, isError, error } = useGetPlagiarismInspectDetail(id);

  return (
    <div>
      {!data && <Blank />}
      {data && <ExternalHTML html={data} allowRerender />}
      {isError && <ErrorBlank text="표절 검사 결과를 불러오는데 실패했습니다." />}
    </div>
  );
}
