import clsx from 'clsx';

export interface SolidSVGIconProps {
  color?: 'gray700' | 'gray400' | 'orange800' | 'white';
  size?: number;
  rotate?: number;
  weight?: 'normal' | 'bold';
  onClick?: () => void;
  className?: string;
}

// 공통 SVG 설정 함수
const SVGIconBase = ({
  size = 16,
  rotate = 0,
  className,
  onClick,
  children,
}: SolidSVGIconProps & { children: React.ReactNode }) => {
  return (
    <svg
      width={size}
      height={size}
      onClick={onClick}
      className={clsx(className, {
        'rotate-45': rotate === 45,
        'rotate-90': rotate === 90,
        'rotate-180': rotate === 180,
      })}
      viewBox={`0 0 ${size} ${size}`}
    >
      {children}
    </svg>
  );
};

const HandleIcon = ({ color = 'gray700', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: '#4C5057',
    gray400: '#C7CBD1',
    orange800: '#FF600C',
    white: '#FFFFFF',
  };

  return (
    <SVGIconBase {...props}>
      <path
        d="M7.33464 2.66634C7.33464 3.40272 6.73768 3.99967 6.0013 3.99967C5.26492 3.99967 4.66797 3.40272 4.66797 2.66634C4.66797 1.92996 5.26492 1.33301 6.0013 1.33301C6.73768 1.33301 7.33464 1.92996 7.33464 2.66634Z"
        fill={colors[color]}
      />
      <path
        d="M7.33464 7.99972C7.33464 8.73609 6.73768 9.33305 6.0013 9.33305C5.26492 9.33305 4.66797 8.73609 4.66797 7.99972C4.66797 7.26334 5.26492 6.66638 6.0013 6.66638C6.73768 6.66638 7.33464 7.26334 7.33464 7.99972Z"
        fill={colors[color]}
      />
      <path
        d="M7.33464 13.3331C7.33464 14.0695 6.73768 14.6664 6.0013 14.6664C5.26492 14.6664 4.66797 14.0695 4.66797 13.3331C4.66797 12.5967 5.26492 11.9998 6.0013 11.9998C6.73768 11.9998 7.33464 12.5967 7.33464 13.3331Z"
        fill={colors[color]}
      />
      <path
        d="M12.0016 2.66638C12.0016 3.40276 11.4047 3.99972 10.6683 3.99972C9.93191 3.99972 9.33496 3.40276 9.33496 2.66638C9.33496 1.93 9.93191 1.33305 10.6683 1.33305C11.4047 1.33305 12.0016 1.93 12.0016 2.66638Z"
        fill={colors[color]}
      />
      <path
        d="M12.0016 7.99972C12.0016 8.73609 11.4047 9.33305 10.6683 9.33305C9.93191 9.33305 9.33496 8.73609 9.33496 7.99972C9.33496 7.26334 9.93191 6.66638 10.6683 6.66638C11.4047 6.66638 12.0016 7.26334 12.0016 7.99972Z"
        fill={colors[color]}
      />
      <path
        d="M12.0016 13.333C12.0016 14.0694 11.4047 14.6664 10.6683 14.6664C9.93191 14.6664 9.33496 14.0694 9.33496 13.333C9.33496 12.5967 9.93191 11.9997 10.6683 11.9997C11.4047 11.9997 12.0016 12.5967 12.0016 13.333Z"
        fill={colors[color]}
      />
    </SVGIconBase>
  );
};
const BellIcon = ({ color = 'gray700', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#D8DBDF',
      secondary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
      secondary: '#C7CBD1',
    },
    orange800: {
      primary: '#FFBC99',
      secondary: '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      <path
        d="M2.68892 11.5H13.3111C13.8546 11.5 14.1834 10.8981 13.8904 10.4394L12.6099 8.43468C12.6018 8.42203 12.5975 8.40733 12.5975 8.39231V6.10647C12.5975 3.56239 10.5391 1.5 8 1.5C5.46087 1.5 3.40249 3.56239 3.40249 6.10647V8.39231C3.40249 8.40733 3.3982 8.42203 3.39012 8.43468L2.10961 10.4394C1.81661 10.8981 2.14538 11.5 2.68892 11.5Z"
        fill={selectedColors.primary}
      />
      <path
        d="M8 14.45C9.10457 14.45 10 13.5557 10 12.4524V12.25H6V12.4524C6 13.5557 6.89543 14.45 8 14.45Z"
        fill={selectedColors.secondary}
      />
    </SVGIconBase>
  );
};

const HomeIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: weight === 'bold' ? '#4C5057' : '#D8DBDF',
      secondary: weight === 'bold' ? '#61656B' : '#4C5057',
    },
    gray400: {
      primary: weight === 'bold' ? '#C7CBD1' : '#E8EAED',
      secondary: weight === 'bold' ? '#C7CBD1' : '#C7CBD1',
    },
    orange800: {
      primary: weight === 'bold' ? '#FF600C' : '#FFBC99',
      secondary: weight === 'bold' ? '#FF600C' : '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: weight === 'bold' ? '#E8EAED' : 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      {weight === 'bold' ? (
        <path
          d="M8.81727 2.27048C8.33055 1.90984 7.66944 1.90984 7.18273 2.27048L2.94792 5.40833C2.35046 5.85102 2 6.55594 2 7.3028V13C2 13.5523 2.44772 14 3 14H6V9.90909C6 9.40701 6.44772 9 7 9H9C9.55228 9 10 9.40701 10 9.90909V14H13C13.5523 14 14 13.5523 14 13V7.3028C14 6.55594 13.6495 5.85102 13.0521 5.40833L8.81727 2.27048Z"
          fill={selectedColors.primary}
        />
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.18273 2.27048C7.66944 1.90984 8.33055 1.90984 8.81727 2.27048L13.0521 5.40833C13.6495 5.85102 14 6.55594 14 7.3028V12.5969C14 13.3631 13.3887 14 12.6154 14H3.38462C2.61131 14 2 13.3631 2 12.5969V7.3028C2 6.55594 2.35046 5.85102 2.94792 5.40833L7.18273 2.27048Z"
            fill={selectedColors.primary}
          />
          <path
            d="M5.75 9.90909C5.75 9.40701 6.25368 9 6.875 9H9.125C9.74632 9 10.25 9.40701 10.25 9.90909V14H5.75V9.90909Z"
            fill={selectedColors.secondary}
          />
        </>
      )}
    </SVGIconBase>
  );
};

const TalkIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#E8EAED',
      secondary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
      secondary: 'white',
    },
    orange800: {
      primary: '#FFE8DB',
      secondary: '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: '#E8EAED',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      {weight === 'bold' ? (
        <>
          <path
            d="M1.53974 19.5042L2.77155 15.8862C2.10259 14.6931 1.75207 13.3609 1.751 12.0076C1.751 7.0415 6.40522 3 12.1255 3C17.8458 3 22.5 7.0415 22.5 12.0076C22.5 16.966 17.8474 20.9998 12.1255 20.9998C10.0152 21.013 7.9457 20.4453 6.16471 19.3646L2.56354 20.4816C2.42103 20.5258 2.2685 20.5315 2.12281 20.4981C1.97712 20.4647 1.84394 20.3935 1.73799 20.2923C1.63204 20.1912 1.55745 20.064 1.52246 19.9249C1.48747 19.7859 1.49345 19.6402 1.53974 19.5042Z"
            fill={selectedColors.primary}
          />
          <path
            d="M8.96618 12.1741C8.96618 12.8268 8.43706 13.356 7.78436 13.356C7.13166 13.356 6.60254 12.8268 6.60254 12.1741C6.60254 11.5214 7.13166 10.9923 7.78436 10.9923C8.43706 10.9923 8.96618 11.5214 8.96618 12.1741Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M13.3559 12.174C13.3559 12.8267 12.8267 13.3558 12.174 13.3558C11.5213 13.3558 10.9922 12.8267 10.9922 12.174C10.9922 11.5213 11.5213 10.9922 12.174 10.9922C12.8267 10.9922 13.3559 11.5213 13.3559 12.174Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M17.7455 12.174C17.7455 12.8267 17.2163 13.3558 16.5636 13.3558C15.9109 13.3558 15.3818 12.8267 15.3818 12.174C15.3818 11.5213 15.9109 10.9922 16.5636 10.9922C17.2163 10.9922 17.7455 11.5213 17.7455 12.174Z"
            fill={selectedColors.secondary}
          />
        </>
      ) : (
        <>
          <path
            d="M1.02649 13.0028L1.8477 10.5908C1.40173 9.79541 1.16804 8.90729 1.16733 8.0051C1.16733 4.69433 4.27015 2 8.08367 2C11.8972 2 15 4.69433 15 8.0051C15 11.3107 11.8983 13.9998 8.08367 13.9998C6.67682 14.0087 5.29713 13.6302 4.10981 12.9097L1.70903 13.6544C1.61402 13.6839 1.51233 13.6877 1.41521 13.6654C1.31808 13.6431 1.22929 13.5957 1.15866 13.5282C1.08802 13.4608 1.0383 13.376 1.01497 13.2833C0.99165 13.1906 0.995636 13.0935 1.02649 13.0028Z"
            fill={selectedColors.primary}
          />
          <path
            d="M5.75 8C5.75 8.41421 5.41421 8.75 5 8.75C4.58579 8.75 4.25 8.41421 4.25 8C4.25 7.58579 4.58579 7.25 5 7.25C5.41421 7.25 5.75 7.58579 5.75 8Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M8.75 8C8.75 8.41421 8.41421 8.75 8 8.75C7.58579 8.75 7.25 8.41421 7.25 8C7.25 7.58579 7.58579 7.25 8 7.25C8.41421 7.25 8.75 7.58579 8.75 8Z"
            fill={selectedColors.secondary}
          />
          <path
            d="M11.75 8C11.75 8.41421 11.4142 8.75 11 8.75C10.5858 8.75 10.25 8.41421 10.25 8C10.25 7.58579 10.5858 7.25 11 7.25C11.4142 7.25 11.75 7.58579 11.75 8Z"
            fill={selectedColors.secondary}
          />
        </>
      )}
    </SVGIconBase>
  );
};

const InfoIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#D8DBDF',
      secondary: '#4C5057',
    },
    gray400: {
      primary: '#E8EAED',
      secondary: '#898D94',
    },
    orange800: {
      primary: '#FFD8C2',
      secondary: '#FF600C',
    },
    white: {
      primary: 'white',
      secondary: '#E8EAED',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      {weight === 'bold' ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.99977 6.09916C8.41386 6.09916 8.74955 5.76347 8.74955 5.34938C8.74955 4.9353 8.41386 4.59961 7.99977 4.59961C7.58569 4.59961 7.25 4.9353 7.25 5.34938C7.25 5.76347 7.58569 6.09916 7.99977 6.09916ZM8.75 7.82941C8.75 7.41532 8.41431 7.07963 8.00023 7.07963C7.58614 7.07963 7.25045 7.41532 7.25045 7.82941V10.8508C7.25045 11.2649 7.58614 11.6006 8.00023 11.6006C8.41431 11.6006 8.75 11.2649 8.75 10.8508V7.82941Z"
            fill={selectedColors.secondary}
          />
        </>
      ) : (
        <>
          <circle cx="8" cy="8" r="7" fill={selectedColors.primary} />
          <path
            d="M8.60003 5.34982C8.60003 5.68109 8.33148 5.94964 8.00021 5.94964C7.66894 5.94964 7.40039 5.68109 7.40039 5.34982C7.40039 5.01855 7.66894 4.75 8.00021 4.75C8.33148 4.75 8.60003 5.01855 8.60003 5.34982Z"
            fill={selectedColors.secondary}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00057 7C8.33184 7 8.60039 7.26855 8.60039 7.59982V10.9002C8.60039 11.2315 8.33184 11.5 8.00057 11.5C7.6693 11.5 7.40075 11.2315 7.40075 10.9002V7.59982C7.40075 7.26855 7.6693 7 8.00057 7Z"
            fill={selectedColors.secondary}
          />
        </>
      )}
    </SVGIconBase>
  );
};

const MoreIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const isBold = weight === 'bold';

  const colors = {
    gray700: {
      primary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
    },
    orange800: {
      primary: '#FF600C',
    },
    white: {
      primary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isBold ? 12 : 10}
      height={2}
      viewBox="0 0 12 2"
      fill="none"
      {...props}
    >
      {isBold ? (
        <>
          <circle cx="1.5" cy="1" r="1" fill={selectedColors.primary} />
          <circle cx="6" cy="1" r="1" fill={selectedColors.primary} />
          <circle cx="10.5" cy="1" r="1" fill={selectedColors.primary} />
        </>
      ) : (
        <>
          <circle cx="1" cy="1" r="0.75" fill={selectedColors.primary} />
          <circle cx="5" cy="1" r="0.75" fill={selectedColors.primary} />
          <circle cx="9" cy="1" r="0.75" fill={selectedColors.primary} />
        </>
      )}
    </svg>
  );
};

const ProfileIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
    },
    orange800: {
      primary: '#FF600C',
    },
    white: {
      primary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      <path
        d="M11 5.75C11 7.40685 9.65685 8.75 8 8.75C6.34315 8.75 5 7.40685 5 5.75C5 4.09315 6.34315 2.75 8 2.75C9.65685 2.75 11 4.09315 11 5.75Z"
        fill={selectedColors.primary}
      />
      <path
        d="M8 8.75C5.58625 8.75 3.57198 10.0783 3.10303 12.257C2.98682 12.797 3.44772 13.25 4 13.25H12C12.5523 13.25 13.0132 12.797 12.897 12.257C12.428 10.0783 10.4137 8.75 8 8.75Z"
        fill={selectedColors.primary}
      />
    </SVGIconBase>
  );
};

const BulletIcon = ({ color = 'gray700', weight = 'normal', ...props }: SolidSVGIconProps) => {
  const colors = {
    gray700: {
      primary: '#4C5057',
    },
    gray400: {
      primary: '#C7CBD1',
    },
    orange800: {
      primary: '#FF600C',
    },
    white: {
      primary: 'white',
    },
  };

  const selectedColors = colors[color];

  return (
    <SVGIconBase {...props}>
      <circle cx="8" cy="10" r="2" fill={selectedColors.primary} />
    </SVGIconBase>
  );
};

const CreIcon = ({ color = 'orange800', ...props }: SolidSVGIconProps) => {
  switch (color) {
    case 'gray700':
      return (
        <svg width={props.size} height={props.size} viewBox={`0 0 24 24`} xmlns="http://www.w3.org/2000/svg">
          <rect
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            rx="4"
            fill="#F4F6F8"
            x="0"
            y="0"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M12.3906 8.28125C12.1797 7.19141 11.2773 6.58203 10.1992 6.58203C8.73438 6.58203 7.64453 7.70703 7.64453 9.75781C7.64453 11.8203 8.74609 12.9336 10.1992 12.9336C11.2656 12.9336 12.168 12.3359 12.3906 11.2578L13.6914 11.2695C13.4219 12.9453 12.0508 14.1172 10.1875 14.1172C7.97266 14.1172 6.36719 12.4648 6.36719 9.75781C6.36719 7.03906 7.98438 5.39844 10.1875 5.39844C11.9805 5.39844 13.3984 6.45312 13.6914 8.28125H12.3906Z"
            fill="#4C5057"
          />
        </svg>
      );
    case 'orange800':
      return (
        <SVGIconBase {...props}>
          {props.size === 20 ? (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx="4" fill="#FFE8DB" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12.3906 8.28125C12.1797 7.19141 11.2773 6.58203 10.1992 6.58203C8.73438 6.58203 7.64453 7.70703 7.64453 9.75781C7.64453 11.8203 8.74609 12.9336 10.1992 12.9336C11.2656 12.9336 12.168 12.3359 12.3906 11.2578L13.6914 11.2695C13.4219 12.9453 12.0508 14.1172 10.1875 14.1172C7.97266 14.1172 6.36719 12.4648 6.36719 9.75781C6.36719 7.03906 7.98438 5.39844 10.1875 5.39844C11.9805 5.39844 13.3984 6.45312 13.6914 8.28125H12.3906Z"
                fill="#FF600C"
              />
            </>
          ) : props.size === 24 ? (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx="4" fill="#FFE8DB" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M14.1602 10.8047C13.9316 9.62402 12.9541 8.96387 11.7861 8.96387C10.1992 8.96387 9.01855 10.1826 9.01855 12.4043C9.01855 14.6387 10.2119 15.8447 11.7861 15.8447C12.9414 15.8447 13.9189 15.1973 14.1602 14.0293L15.5693 14.042C15.2773 15.8574 13.792 17.127 11.7734 17.127C9.37402 17.127 7.63477 15.3369 7.63477 12.4043C7.63477 9.45898 9.38672 7.68164 11.7734 7.68164C13.7158 7.68164 15.252 8.82422 15.5693 10.8047H14.1602Z"
                fill="#FF600C"
              />
            </>
          ) : (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width="16" height="16" rx="4" fill="#FFE8DB" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M10.1914 6.75781C9.99805 5.75879 9.1709 5.2002 8.18262 5.2002C6.83984 5.2002 5.84082 6.23145 5.84082 8.11133C5.84082 10.002 6.85059 11.0225 8.18262 11.0225C9.16016 11.0225 9.9873 10.4746 10.1914 9.48633L11.3838 9.49707C11.1367 11.0332 9.87988 12.1074 8.17188 12.1074C6.1416 12.1074 4.66992 10.5928 4.66992 8.11133C4.66992 5.61914 6.15234 4.11523 8.17188 4.11523C9.81543 4.11523 11.1152 5.08203 11.3838 6.75781H10.1914Z"
                fill="#FF600C"
              />
            </>
          )}
        </SVGIconBase>
      );
    default:
      return null;
  }
};
const ActIcon = ({ color = 'orange800', ...props }: SolidSVGIconProps) => {
  switch (color) {
    case 'gray700':
      return (
        <SVGIconBase {...props}>
          <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx="4" fill="#F4F6F8" />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M7.58008 14H6.20898L9.25586 5.51562H10.7441L13.8027 14H12.4316L11.6582 11.7617H8.35352L7.58008 14ZM8.72852 10.6836H11.2832L10.0293 7.0625H9.9707L8.72852 10.6836Z"
            fill="#4C5057"
          />
        </SVGIconBase>
      );
    case 'orange800':
      return (
        <SVGIconBase {...props}>
          {props.size === 20 ? (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx={4} fill="#DBEEFF" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.58008 14H6.20898L9.25586 5.51562H10.7441L13.8027 14H12.4316L11.6582 11.7617H8.35352L7.58008 14ZM8.72852 10.6836H11.2832L10.0293 7.0625H9.9707L8.72852 10.6836Z"
                fill="#0066FF"
              />
            </>
          ) : props.size === 24 ? (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx={4} fill="#DBEEFF" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M9.22656 17H7.74121L11.042 7.80859H12.6543L15.9678 17H14.4824L13.6445 14.5752H10.0645L9.22656 17ZM10.4707 13.4072H13.2383L11.8799 9.48438H11.8164L10.4707 13.4072Z"
                fill="#0066FF"
              />
            </>
          ) : (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width="16" height="16" rx="4" fill="#DBEEFF" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M5.78174 12H4.5249L7.31787 4.22266H8.68213L11.4858 12H10.229L9.52002 9.94824H6.49072L5.78174 12ZM6.83447 8.95996H9.17627L8.02686 5.64062H7.97314L6.83447 8.95996Z"
                fill="#0066FF"
              />
            </>
          )}
        </SVGIconBase>
      );
    default:
      return null;
  }
};
const SerIcon = ({ color = 'orange800', ...props }: SolidSVGIconProps) => {
  switch (color) {
    case 'gray700':
      return (
        <SVGIconBase {...props}>
          <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx="4" fill="#F4F6F8" />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M11.7988 7.75391C11.7168 6.96875 11.0254 6.51172 10.0527 6.51172C9.00977 6.51172 8.35352 7.03906 8.35352 7.76562C8.35352 8.57422 9.23242 8.87891 9.90039 9.04297L10.7207 9.26562C11.8105 9.52344 13.1582 10.1211 13.1699 11.6562C13.1582 13.0859 12.0215 14.1406 10.0176 14.1406C8.11914 14.1406 6.92383 13.1914 6.8418 11.6445H8.13086C8.21289 12.5703 9.02148 13.0156 10.0176 13.0156C11.0957 13.0156 11.8809 12.4648 11.8926 11.6328C11.8809 10.8945 11.1895 10.5898 10.2871 10.3555L9.2793 10.0859C7.91992 9.71094 7.06445 9.01953 7.06445 7.83594C7.06445 6.37109 8.37695 5.39844 10.0879 5.39844C11.8105 5.39844 13.0176 6.38281 13.0527 7.75391H11.7988Z"
            fill="#4C5057"
          />
        </SVGIconBase>
      );
    case 'orange800':
      return (
        <SVGIconBase {...props}>
          {props.size === 20 ? (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx="4" fill="#DDF4ED" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M11.7988 7.75391C11.7168 6.96875 11.0254 6.51172 10.0527 6.51172C9.00977 6.51172 8.35352 7.03906 8.35352 7.76562C8.35352 8.57422 9.23242 8.87891 9.90039 9.04297L10.7207 9.26562C11.8105 9.52344 13.1582 10.1211 13.1699 11.6562C13.1582 13.0859 12.0215 14.1406 10.0176 14.1406C8.11914 14.1406 6.92383 13.1914 6.8418 11.6445H8.13086C8.21289 12.5703 9.02148 13.0156 10.0176 13.0156C11.0957 13.0156 11.8809 12.4648 11.8926 11.6328C11.8809 10.8945 11.1895 10.5898 10.2871 10.3555L9.2793 10.0859C7.91992 9.71094 7.06445 9.01953 7.06445 7.83594C7.06445 6.37109 8.37695 5.39844 10.0879 5.39844C11.8105 5.39844 13.0176 6.38281 13.0527 7.75391H11.7988Z"
                fill="#00A876"
              />
            </>
          ) : props.size === 24 ? (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} rx="4" fill="#DDF4ED" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M13.9414 10.2334C13.8525 9.38281 13.1035 8.8877 12.0498 8.8877C10.9199 8.8877 10.209 9.45898 10.209 10.2461C10.209 11.1221 11.1611 11.4521 11.8848 11.6299L12.7734 11.8711C13.9541 12.1504 15.4141 12.7979 15.4268 14.4609C15.4141 16.0098 14.1826 17.1523 12.0117 17.1523C9.95508 17.1523 8.66016 16.124 8.57129 14.4482H9.96777C10.0566 15.4512 10.9326 15.9336 12.0117 15.9336C13.1797 15.9336 14.0303 15.3369 14.043 14.4355C14.0303 13.6357 13.2812 13.3057 12.3037 13.0518L11.2119 12.7598C9.73926 12.3535 8.8125 11.6045 8.8125 10.3223C8.8125 8.73535 10.2344 7.68164 12.0879 7.68164C13.9541 7.68164 15.2617 8.74805 15.2998 10.2334H13.9414Z"
                fill="#00A876"
              />
            </>
          ) : (
            <>
              <rect xmlns="http://www.w3.org/2000/svg" width="16" height="16" rx="4" fill="#DDF4ED" />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M9.64893 6.27441C9.57373 5.55469 8.93994 5.13574 8.04834 5.13574C7.09229 5.13574 6.49072 5.61914 6.49072 6.28516C6.49072 7.02637 7.29639 7.30566 7.90869 7.45605L8.66064 7.66016C9.65967 7.89648 10.895 8.44434 10.9058 9.85156C10.895 11.1621 9.85303 12.1289 8.01611 12.1289C6.27588 12.1289 5.18018 11.2588 5.10498 9.84082H6.28662C6.36182 10.6895 7.10303 11.0977 8.01611 11.0977C9.00439 11.0977 9.72412 10.5928 9.73486 9.83008C9.72412 9.15332 9.09033 8.87402 8.26318 8.65918L7.33936 8.41211C6.09326 8.06836 5.30908 7.43457 5.30908 6.34961C5.30908 5.00684 6.51221 4.11523 8.08057 4.11523C9.65967 4.11523 10.7661 5.01758 10.7983 6.27441H9.64893Z"
                fill="#00A876"
              />
            </>
          )}
        </SVGIconBase>
      );
    default:
      return null;
  }
};

const SolidSVGIcon = {
  Bell: BellIcon,
  Home: HomeIcon,
  Talk: TalkIcon,
  More: MoreIcon,
  Profile: ProfileIcon,
  Handle: HandleIcon,
  Bullet: BulletIcon,
  Info: InfoIcon,
  C: CreIcon,
  S: SerIcon,
  A: ActIcon,
};

export default SolidSVGIcon;
