import clsx from 'clsx';
import { useState } from 'react';
import { SelectValues, SuperModal } from 'src/components';
import { Badge, Blank, Label, Section, Textarea } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import { useTeacherOutingUpdate } from 'src/container/teacher-outing-update';
import { OutingTypeEnum, ResponseCreateOutingDto } from 'src/generated/model';
import { AbsentTimeType } from 'src/types';

interface OutingUpdatePageProps {
  outingData: ResponseCreateOutingDto;
  setChangeMode: (b: boolean) => void;
  isConfirmed: boolean;
}

export function OutingUpdatePage({ outingData, setChangeMode }: OutingUpdatePageProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    updateReason,
    setUpdateReason,
    reportedAt,
    setReportedAt,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    startHour,
    setStartHour,
    startMinute,
    setStartMinute,
    endHour,
    setEndHour,
    endMinute,
    setEndMinute,
    timeType,
    setTimeType,
    startPeriod,
    endPeriod,
    setStartPeriod,
    setEndPeriod,
    report,
    setReport,
    reason,
    setReason,
    errorMessage,
    isLoading,
    updateOuting,
    outingValueSel,
    setOutingValueSel,
    outingValue,
  } = useTeacherOutingUpdate({ outingData, setChangeMode });

  return (
    <>
      {isLoading && <Blank />}
      <Section>
        <Label.col>
          <Label.Text children="학생 이름(본인)" />
          <TextInput value={outingData?.studentName || ''} disabled />
        </Label.col>
        <Label.col>
          <Label.Text children="학생 학년/반/번호" />
          <TextInput value={`${outingData?.studentGradeKlass} ${outingData?.studentNumber}번`} disabled />
        </Label.col>
        <Label.col>
          <Label.Text children="*유형" />
          <TextInput value={report} disabled />
          {report === OutingTypeEnum.확인 && <TextInput value={outingValueSel} disabled />}
        </Label.col>
        <Label.col>
          <Label.Text children="*사유" />
          <TextInput
            placeholder="조퇴/외출/확인 사유를 자세하게 입력해주세요"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Label.col>
        <div className="w-full">
          <label className="mb-1 text-sm text-gray-800">*신고일 : </label>
          <div>
            <input
              type="date"
              value={reportedAt}
              onChange={(e) => setReportedAt(e.target.value)}
              className="h-12 w-full rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
            />
          </div>
        </div>
        <div className="w-full pb-6">
          <label className="mb-1 text-sm text-gray-800">*기간 : </label>
          <Badge
            children="교시설정"
            onClick={() => setTimeType(AbsentTimeType.PERIOD)}
            className={clsx(
              'py-1.5',
              timeType === AbsentTimeType.PERIOD ? 'bg-brand-1 text-white' : 'bg-white text-black',
            )}
          />
          <Badge
            children="시간설정"
            onClick={() => setTimeType(AbsentTimeType.TIME)}
            className={clsx(
              'py-1.5',
              timeType === AbsentTimeType.TIME ? 'bg-brand-1 text-white' : 'bg-white text-black',
            )}
          />
          <div className="space-y-3 pb-6">
            <div>
              <input
                type="date"
                value={startAt}
                onChange={(e) => setStartAt(e.target.value)}
                className="h-12 w-full rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
              />
            </div>
            {timeType === AbsentTimeType.TIME && (
              <div className="flex items-center space-x-2">
                <select
                  value={startHour}
                  onChange={(e) => setStartHour(Number(e.target.value))}
                  className="h-12 w-16 min-w-max rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                >
                  {new Array(24).fill(null).map((item, num: number) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                <span>시</span>
                <select
                  value={startMinute}
                  onChange={(e) => setStartMinute(Number(e.target.value))}
                  className="h-12 w-16 min-w-max rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                >
                  <option value={0}>0</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
                <span>분 부터</span>
              </div>
            )}
            {timeType === AbsentTimeType.PERIOD && (
              <div className="flex items-center space-x-2">
                <SelectValues
                  placeholder="선택"
                  selectValues={['조회', '1', '2', '3', '4', '5', '6', '7', '8', '9', '종례']}
                  value={startPeriod}
                  onChange={(stime: string) => {
                    if (stime !== '조회' && (endPeriod === '조회' || stime > endPeriod)) {
                      setEndPeriod(stime);
                    }
                    setStartPeriod(stime);
                  }}
                  className={startPeriod !== '0' ? 'w-16 border border-gray-300' : 'w-16 border-2 border-red-700'}
                />
                <span className="text-sm"> 교시부터 </span>
              </div>
            )}
            {report === '확인' && (
              <div>
                <input
                  type="date"
                  lang="ko-KR"
                  value={endAt}
                  onChange={(e) => setEndAt(e.target.value)}
                  className="h-12 w-full rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                />
              </div>
            )}
            {timeType === AbsentTimeType.TIME && (
              <div className="flex items-center space-x-2">
                <select
                  value={endHour}
                  onChange={(e) => setEndHour(Number(e.target.value))}
                  className="h-12 w-16 min-w-max rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                >
                  {new Array(24).fill(null).map((item, num: number) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
                <span>시</span>
                <select
                  value={endMinute}
                  onChange={(e) => setEndMinute(Number(e.target.value))}
                  className="h-12 w-16 min-w-max rounded-md border border-gray-200 px-4 placeholder-gray-400 focus:border-brand-1 focus:ring-0 disabled:bg-gray-100 disabled:text-gray-400 sm:text-sm"
                >
                  <option value={0}>0</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
                <span>분 까지</span>
              </div>
            )}
            {timeType === AbsentTimeType.PERIOD && (
              <div className="flex items-center space-x-2">
                <SelectValues
                  placeholder="선택"
                  selectValues={['조회', '1', '2', '3', '4', '5', '6', '7', '8', '9', '종례']}
                  value={endPeriod}
                  onChange={(etime: string) => {
                    if (startPeriod !== '조회' && (etime === '조회' || etime < startPeriod)) {
                      setStartPeriod(etime);
                    }
                    setEndPeriod(etime);
                  }}
                  className={endPeriod !== '0' ? 'w-16 border border-gray-300' : 'w-16 border-2 border-red-700'}
                />
                <span className="text-sm"> 교시까지 </span>
              </div>
            )}
          </div>
        </div>

        {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        <div className="mt-3 flex w-full items-center space-x-2">
          <Button.xl
            children="수정하기"
            disabled={!(reason && report)}
            onClick={() => setModalOpen(true)}
            className="filled-primary w-full"
          />
          <Button.xl children="취소하기" onClick={() => setChangeMode(false)} className="filled-gray w-full" />
        </div>
      </Section>
      <SuperModal modalOpen={modalOpen} setModalClose={() => setModalOpen(false)} className="w-max">
        <Section className="mt-7">
          <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
            이 확인증을 수정하시는 이유를 적어주세요.
          </div>
          <Textarea placeholder="수정 이유" value={updateReason} onChange={(e) => setUpdateReason(e.target.value)} />
          <Button.xl
            children="수정하기"
            disabled={!updateReason}
            onClick={() => updateOuting(outingData.studentId)}
            className="filled-red"
          />
        </Section>
      </SuperModal>
    </>
  );
}
