import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { SuperModal } from 'src/components';
import { Label, Select } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import { teacherPointLogCreate, useTeacherPointGet, useTeacherUserGetOne } from 'src/generated/endpoint';
import { PointLogCreateBody } from 'src/generated/model';
import { form } from 'src/lib/form';
import { cn } from 'src/lib/tailwind-merge';
import { toastState } from 'src/store';
import { getNickName } from 'src/util/status';
import { numberWithSign } from 'src/util/string';
import { useModals } from './ModalStack';

export interface AssignPointModalProps {
  studentId: number;
}

export function AssignPointModal({ studentId }: AssignPointModalProps) {
  const { t } = useTranslation();
  const { t: tm } = useTranslation('modal', { keyPrefix: 'assign_point_modal' });
  const { popModal } = useModals();
  const setToastMsg = useSetRecoilState(toastState);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<PointLogCreateBody>({ defaultValues: { studentId, value: 1 } });

  const { data: student } = useTeacherUserGetOne(studentId, {});
  const { data: points } = useTeacherPointGet({ size: 10000 });

  useEffect(() => points?.items[0] && setValue('pointId', points.items[0].id), [points]);

  async function assign(body: PointLogCreateBody) {
    const ok = confirm(`실행할까요?`);
    if (!ok) return;
    if (body.pointId) {
      delete body.title;
      delete body.value;
    }
    const pointLog = await teacherPointLogCreate(body);
    setToastMsg(`실행되었습니다: ${pointLog.title} (${pointLog.value})`);
    popModal();
  }

  const pointValue = watch('value') ?? 1;

  return (
    <SuperModal
      modalOpen
      setModalClose={popModal}
      className="flex w-96 flex-col pb-4 pt-16 max-md:h-screen-3.5 max-md:w-full max-md:justify-around max-md:self-start max-md:rounded-none md:max-h-screen-48 md:gap-8"
    >
      <div className="flex flex-col gap-4 px-4">
        <div>
          <Label.Text children={tm('recipient')} />
          <p className="p-2 text-18 font-medium">
            {student?.name} {getNickName(student?.nickName)}
          </p>
        </div>
        <Label.col>
          <Label.Text children={'*' + tm('details')} />
          <Select.lg {...register('pointId', { valueAsNumber: true })}>
            <option>{tm('enter_manually')}</option>
            {points?.items.map((point) => (
              <option key={point.id} value={point.id}>
                {point.title} ({numberWithSign(point.value)})
              </option>
            ))}
          </Select.lg>
        </Label.col>
        {!watch('pointId') && (
          <Label.col>
            <Label.Text children={'*' + tm('point_log_title')} />
            <TextInput autoFocus {...register('title', form.length(1, 100))} />
            <Label.Error children={errors.title?.message} />
          </Label.col>
        )}
        {!watch('pointId') && (
          <div className="flex items-end gap-2">
            <Button
              children="-"
              onClick={() => setValue('value', -Math.abs(pointValue))}
              className={cn('mb-1 h-12 w-12 text-24', pointValue < 0 ? 'filled-gray' : 'outlined-gray')}
            />
            <Button
              children="+"
              onClick={() => setValue('value', Math.abs(pointValue))}
              className={cn('mb-1 h-12 w-12 text-24', pointValue > 0 ? 'filled-gray' : 'outlined-gray')}
            />
            <Label.col className="flex-1">
              <Label.Text children={'*' + tm('point_log_value')} />
              <TextInput type="number" min={-100} max={100} {...register('value', form.minmax(-100, 100))} />
              <Label.Error children={errors.value?.message} />
            </Label.col>
          </div>
        )}
      </div>

      <div className="flex gap-4 px-4">
        <Button.lg children={t('cancel')} onClick={popModal} className="outlined-gray flex-1" />
        <Button.lg children={tm('assign')} onClick={handleSubmit(assign)} className="filled-gray flex-1" />
      </div>
    </SuperModal>
  );
}
