import { useState } from 'react';
import {
  useStudentExamScoreCheckMockScoreFile,
  useStudentExamScoreCheckScoreFile,
  useStudentExamScoreCheckTestScoreFile,
  useStudentExamScoreDeleteMockScore,
  useStudentExamScoreDeleteScoreFile,
  useStudentExamScoreDeleteStudentExamScore,
  useStudentExamScoreInsertMockTestScores,
  useStudentExamScoreInsetClassScoresBulk,
  useStudentExamScoreInsetTestScores,
} from 'src/generated/endpoint';
import {
  StudentExamScoreDeleteScoreFileParams,
  StudentExamScoreInsertMockTestScoresBody,
  StudentExamScoreInsertMockTestScoresParams,
  StudentExamScoreInsetTestScoresBody,
  StudentExamScoreInsetTestScoresParams,
} from 'src/generated/model';
import { queryClient } from 'src/lib/query';
import { examsScoreFiles } from 'src/util/exam-score';

interface Score {
  grade: number;
  class: number;
  semester: number;
}

interface MockScore {
  grade: number;
  res: MockItemList[];
}

interface TestScore {
  class: number;
  month: number;
  isSubmitted: boolean;
}

interface MockItemList {
  semester: number;
  scores: MockItem[];
}

interface MockItem {
  class: number;
  first_test: boolean;
  second_test: boolean;
}

interface SCORE_DATA {
  scores: Score[];
}

interface TEST_DATA {
  grade: number;
  scores: TestScore[];
}

export function useInsertScoreBatch() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { mutateAsync, isLoading, isError } = useStudentExamScoreInsetClassScoresBulk({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['score']);
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  const insertScoreBatch = async ({ file, grade, class_num, cur_year }: examsScoreFiles): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutateAsync({ data: { file }, params: { grade, classNum: class_num, insertionYear: cur_year } })
        .then(() => resolve())
        .catch((error) => {
          console.error(error.message);
          reject(error);
        });
    });
  };

  return {
    insertScoreBatch,
    isLoading,
    errorMessage,
    isError,
  };
}

export function useStudentScoreFileCheck(grade: number, insertionYear: number) {
  const [errorMessage, setErrorMessage] = useState<String | undefined>();
  const { data, isLoading, error, refetch } = useStudentExamScoreCheckScoreFile<SCORE_DATA>(
    { grade, insertionYear },
    {
      query: {
        enabled: !!grade,
        onError: () => {
          setErrorMessage(error?.message);
        },
      },
    },
  );
  return {
    data,
    isLoading,
    errorMessage,
  };
}

export function useStudentScoreFileCheckMock(grade: number, insertionYear: number) {
  const [errorMessage, setErrorMessage] = useState<String | undefined>();
  const { data, isLoading, error } = useStudentExamScoreCheckMockScoreFile<MockScore>(
    { grade, insertionYear },
    {
      query: {
        enabled: !!grade,
        onError: () => {
          setErrorMessage(error?.message);
        },
      },
    },
  );

  return {
    data,
    isLoading,
    errorMessage,
  };
}

export function useStudentScoreFileCheckTest(grade: number, insertionYear: number) {
  const [errorMessage, setErrorMessage] = useState<String | undefined>();
  const { data, isLoading, error } = useStudentExamScoreCheckTestScoreFile<TEST_DATA>(
    { grade, insertionYear },
    {
      query: {
        enabled: !!grade,
        onError: () => {
          setErrorMessage(error?.message);
        },
      },
    },
  );

  return {
    data,
    isLoading,
    errorMessage,
  };
}

export function useStudentInsertMockScores() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { mutateAsync, isLoading, isError } = useStudentExamScoreInsertMockTestScores({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['mockScore']);
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  const insertMockScore = ({
    file,
    grade,
    classNum,
    insertionYear,
    semester,
    step,
  }: StudentExamScoreInsertMockTestScoresParams & StudentExamScoreInsertMockTestScoresBody): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutateAsync({ data: { file }, params: { grade, classNum, insertionYear, semester, step } })
        .then(() => resolve())
        .catch((error) => {
          console.error(error.message);
          reject(error);
        });
    });
  };
  return {
    insertMockScore,
    isLoading,
    errorMessage,
    isError,
  };
}

export function useStudentInsertTestScores() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { mutateAsync, isLoading, isError } = useStudentExamScoreInsetTestScores({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['testScore']);
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  const insertTestScore = ({
    file,
    grade,
    month,
    insertionYear,
    excelDataType,
    excelDataSubType,
  }: StudentExamScoreInsetTestScoresBody & StudentExamScoreInsetTestScoresParams): Promise<void> => {
    return new Promise((resolve, reject) => {
      mutateAsync({ data: { file }, params: { grade, month, insertionYear, excelDataType, excelDataSubType } })
        .then(() => resolve())
        .catch((error) => {
          console.error(error.message);
          reject(error);
        });
    });
  };

  return {
    insertTestScore,
    isLoading,
    errorMessage,
    isError,
  };
}

export function useStudentExamScoreDelete() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { mutateAsync: deleteExamScoreMutate } = useStudentExamScoreDeleteStudentExamScore({
    mutation: {
      onSuccess: (response: any) => {
        if ('error' in response) {
          setErrorMessage(response.error);
        } else {
          setErrorMessage(undefined);
          queryClient.invalidateQueries(['score']);
        }
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  // const deleteExamScore = ({
  //   grade,
  //   classNum,
  //   semester,
  //   insertionYear,
  // }: StudentExamScoreDeleteStudentExamScoreParams) => {
  //   deleteExamScoreMutate({
  //     params: {
  //       grade,
  //       classNum,
  //       semester,
  //       insertionYear,
  //     },
  //   });
  // };
  return {
    deleteExamScoreMutate,
    errorMessage,
  };
}

export function useStudentMockExamScoreDelete() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { mutateAsync: deleteMockExamScoreMutate } = useStudentExamScoreDeleteMockScore({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['mockScore']);
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  // const deleteMockExamScore = ({
  //   grade,
  //   classNum,
  //   semester,
  //   step,
  //   insertionYear,
  // }: StudentExamScoreDeleteMockScoreParams) => {
  //   deleteMockExamScoreMutate({
  //     params: {
  //       grade,
  //       classNum,
  //       semester,
  //       step,
  //       insertionYear,
  //     },
  //   });
  // };
  return {
    deleteMockExamScoreMutate,
    errorMessage,
  };
}

export function useStudentTestExamScoreDelete() {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { mutate: deleteTestExamScoreMutate } = useStudentExamScoreDeleteScoreFile({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(['testScore']);
      },
      onError: (error) => {
        setErrorMessage(error?.message);
      },
    },
  });

  const deleteTestExamScore = ({ grade, classNum, month, insertionYear }: StudentExamScoreDeleteScoreFileParams) => {
    deleteTestExamScoreMutate({
      params: {
        grade,
        classNum,
        month,
        insertionYear,
      },
    });
  };
  return {
    deleteTestExamScore,
    errorMessage,
  };
}
