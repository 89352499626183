/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type IBUnsubmitNotificationType = (typeof IBUnsubmitNotificationType)[keyof typeof IBUnsubmitNotificationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IBUnsubmitNotificationType = {
  IB_PROPOSAL: 'IB_PROPOSAL',
  IB_PROJECT: 'IB_PROJECT',
} as const;
